import React, {useEffect, useState} from "react";
import Banner from "../components/Banner";
import {useUserContext} from "../context/userContext";
import {onValue, ref} from "firebase/database";
import {db} from "../firebase";
import SEO from "../seo/SEO";

function MyEnquiryPage() {
    const [{user}, dispatch] = useUserContext()
    const [enquiry, setEnquiry] = useState([])
    const [fullName, setFullName] = useState("")

    useEffect(() => {
        onValue(ref(db, `easyApplications/enquiry/${user}`), snapshot => {
            if (snapshot.exists()) {
                setEnquiry([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((item) => {
                        setEnquiry((oldArray) => [...oldArray, item])
                    })
                }
            }
        })
        onValue(ref(db, `easyApplications/accounts/${user}`), snapshot => {
            if (snapshot.exists()) {
                const firstName = snapshot.child("firstName").val()
                const surname = snapshot.child("surname").val()

                setFullName(`${firstName} ${surname}`)
            }
        })
    }, [user])

    return (
        <main style={{ paddingTop: 0 }}>
            <SEO title="My Enquiries | Easy Applications"
                 description="Easy Applications is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://apply.easyapplications.co.za" />
            <div className="full-container content-wrapper" style={{ textAlign: "left" }}>
                <div className="row profile-breadcrumb">
                    <div className="col-lg-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li className="breadcrumb-item active">
                                    All Enquiries
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="content-block user-dashboard -family">
                    <div className="bar title bar-inset clearfix">
                        <div className="title">
                            All Enquiries
                        </div>
                        <div className="right-block">
                            <a className="btn btn-primary item user-edit" href="/family/account">
                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="EDIT_2_" viewBox="0 0 31 31" space="preserve" width="12" height="12">
                                    <path fillRule="evenodd" fill="#AACCFF"
                                          d="M30,30H0v-2h30V30z M23,8l-4-4l3-3c0.6-0.6,1.9-1.2,2.5-0.6l2.1,2.1c0.6,0.6,0,1.9-0.6,2.5L23,8z M10,21l-4-4  L18,5l4,4L10,21z M3,24l2-6l4,4L3,24z"></path>
                                </svg>

                                New Enquiry
                            </a>
                        </div>
                    </div>
                    <div id="my-inquiries">
                        <Banner />
                        <div className="table-responsive inquiries-table">
                            <form className="-bottom-margin -left-margin -right-margin">
                                <table className="-no-margin-bottom inquiries-list inquiries-list-all inquiry-list table table-striped" style={{display: "table"}}>
                                    <thead>
                                    <tr>
                                        <th className="checkbox-td">
                                            <div className="form-group">
                                                <div className="checkbox checkbox-single">
                                                    <label>
                                                        <input className="select-checkboxes" type="checkbox" />
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            School Name
                                        </th>
                                        <th>
                                            Message
                                        </th>
                                        <th>
                                            Date Sent
                                        </th>
                                        <th>
                                            Status
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {enquiry.map((item) => (
                                        <tr>
                                            <td className="checkbox-td">
                                                <div className="form-group">
                                                    <div className="checkbox checkbox-single js-input-checkboxes">
                                                        <label>
                                                            <input data-school-name="A+ World Academy"
                                                                   name="inquiries_school_ids[]" type="checkbox" value="13" />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <a className="name" href={`/schools/${item.schoolName}`}>
                                                    {item.schoolName}
                                                </a>
                                            </td>
                                            <td>
                                                {item.message}
                                            </td>
                                            <td>
                                                {`${new Date(item.dateSent).getDate()}/${new Date(item.dateSent).getMonth()+1}/${new Date(item.dateSent).getFullYear()}`}
                                            </td>
                                            <td className="status-td">
                                                <div className="status-action">
                                                    <span className="badge-icon badge-pending">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                                            <g fill="none" fillRule="evenodd">
                                                                <path fill="#FFF" fillOpacity="0" d="M0 0H12V12H0z"></path>
                                                                <path fill="#F59D00"
                                                                      d="M6 12c-3.308 0-6-2.692-6-6s2.692-6 6-6 6 2.692 6 6-2.692 6-6 6zM6 .6C3.105.6.6 3.105.6 6c0 2.895 2.505 5.4 5.4 5.4 2.895 0 5.4-2.505 5.4-5.4C11.4 3.105 8.895.6 6 .6zm2.7 5.7H6c-.207 0-.3-.093-.3-.3V2.4c0-.207.093-.3.3-.3s.3.093.3.3v3.3h2.4c.207 0 .3.093.3.3s-.093.3-.3.3z"></path>
                                                            </g>
                                                        </svg>

                                                        {item.status}
                                                    </span>
                                                    <a className="btn btn-trash btn-notext" rel="nofollow" data-method="delete"
                                                       href="/family/13/delete_inquiries_school">
                                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
                                                             preserveAspectRatio="xMidYMid" width="12" height="12" viewBox="-1 0 31 35">
                                                            <path fillRule="evenodd" className="trash-svg"
                                                                  fill="#a6bedc"
                                                                  d="M25.500,11.500 L25.500,27.500 C25.500,29.709 23.709,31.501 21.500,31.501 L7.500,31.501 C5.291,31.501 3.500,29.709 3.500,27.500 L3.500,11.500 C2.395,11.500 1.500,10.605 1.500,9.500 L1.500,7.500 C1.500,6.395 2.395,5.500 3.500,5.500 L9.500,5.500 L9.500,3.500 C9.500,2.395 10.395,1.500 11.500,1.500 L17.500,1.500 C18.605,1.500 19.500,2.395 19.500,3.500 L19.500,5.500 L25.500,5.500 C26.605,5.500 27.500,6.395 27.500,7.500 L27.500,9.500 C27.500,10.605 26.605,11.500 25.500,11.500 ZM5.500,11.500 L5.500,27.500 C5.500,28.605 6.395,29.501 7.500,29.501 L21.500,29.501 C22.605,29.501 23.500,28.605 23.500,27.500 L23.500,11.500 C22.375,11.500 6.875,11.500 5.500,11.500 ZM11.500,5.500 L11.500,4.500 C11.500,3.948 11.948,3.500 12.500,3.500 L16.500,3.500 C17.052,3.500 17.500,3.948 17.500,4.500 L17.500,5.500 C16.531,5.500 11.500,5.500 11.500,5.500 M24.500,7.500 L4.500,7.500 C3.948,7.500 3.500,7.948 3.500,8.500 C3.500,9.053 3.948,9.500 4.500,9.500 L24.500,9.500 C25.052,9.500 25.500,9.053 25.500,8.500 C25.500,7.948 25.052,7.500 24.500,7.500 ZM9.500,14.500 C10.052,14.500 10.500,14.948 10.500,15.500 L10.500,25.500 C10.500,26.053 10.052,26.500 9.500,26.500 C8.948,26.500 8.500,26.053 8.500,25.500 L8.500,15.500 C8.500,14.948 8.948,14.500 9.500,14.500 ZM14.500,14.500 C15.052,14.500 15.500,14.948 15.500,15.500 L15.500,25.500 C15.500,26.053 15.052,26.500 14.500,26.500 C13.948,26.500 13.500,26.053 13.500,25.500 L13.500,15.500 C13.500,14.948 13.948,14.500 14.500,14.500 ZM19.500,14.500 C20.052,14.500 20.500,14.948 20.500,15.500 L20.500,25.500 C20.500,26.053 20.052,26.500 19.500,26.500 C18.948,26.500 18.500,26.053 18.500,25.500 L18.500,15.500 C18.500,14.948 18.948,14.500 19.500,14.500 Z"></path>
                                                        </svg>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                <div className="modal fade ui-modal" id="confirm-message">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header bar bar-inset">
                                                <div className="title">Confirm Message</div>
                                                <div className="pull-right">
                                                    <button aria-label="Close" className="close" data-dismiss="modal" type="button">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20"
                                                             height="20" viewBox="0 0 20 20">
                                                            <g fill="none" fillRule="evenodd">
                                                                <path fill="#FFF" fillOpacity="0"
                                                                      d="M0 0H20V20H0z"></path>
                                                                <path fill="#859BBB"
                                                                      d="M15.6 5.8L11.4 10l4.2 4.2.7.7c.223.223.7.524.7.864 0 .683-.552 1.237-1.235 1.237-.341 0-.641-.478-.864-.701l-.701-.7-4.2-4.2-4.2 4.2-.7.7c-.223.223-.524.7-.864.7C3.553 17 3 16.448 3 15.765c0-.34.477-.64.7-.864l.7-.7L8.6 10 4.4 5.8l-.7-.7c-.223-.223-.7-.524-.7-.864C3 3.553 3.553 3 4.236 3c.34 0 .64.477.864.7l.7.7L10 8.6l4.2-4.2.7-.7c.224-.223.524-.7.865-.7C16.448 3 17 3.553 17 4.236c0 .34-.477.64-.7.864l-.7.7z"></path>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="modal-body">
                                                <div className="res-modal">
                                                    <p className="js-modal-message"></p>
                                                </div>
                                                <div className="bar bar-inset clearfix bar-bottom">
                                                    <div className="pull-right">
                                                        <a className="btn btn-warning" data-dismiss="modal" href="#">Cancel</a>
                                                        <span className="or modal-confirm-btn">
                                                            or
                                                            <input type="submit" name="commit" value="Confirm" className="btn btn-primary" data-disable-with="Confirm" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="inquiries-mobile">
                            {enquiry.map((item) => (
                                <div className="row table-box">
                                    <div className="col-xs-12">
                                        <div className="d-flex align-center school-title">
                                            <div className="box">
                                                <a className="name" href={`/schools/${item.schoolName}`}>
                                                    {item.schoolName}
                                                </a>
                                            </div>
                                            <a className="btn btn-trash btn-notext flex-right" rel="nofollow" data-method="delete" href="#">
                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
                                                     preserveAspectRatio="xMidYMid" width="12" height="12" viewBox="-1 0 31 35">
                                                    <path fillRule="evenodd" className="trash-svg" fill="#a6bedc"
                                                          d="M25.500,11.500 L25.500,27.500 C25.500,29.709 23.709,31.501 21.500,31.501 L7.500,31.501 C5.291,31.501 3.500,29.709 3.500,27.500 L3.500,11.500 C2.395,11.500 1.500,10.605 1.500,9.500 L1.500,7.500 C1.500,6.395 2.395,5.500 3.500,5.500 L9.500,5.500 L9.500,3.500 C9.500,2.395 10.395,1.500 11.500,1.500 L17.500,1.500 C18.605,1.500 19.500,2.395 19.500,3.500 L19.500,5.500 L25.500,5.500 C26.605,5.500 27.500,6.395 27.500,7.500 L27.500,9.500 C27.500,10.605 26.605,11.500 25.500,11.500 ZM5.500,11.500 L5.500,27.500 C5.500,28.605 6.395,29.501 7.500,29.501 L21.500,29.501 C22.605,29.501 23.500,28.605 23.500,27.500 L23.500,11.500 C22.375,11.500 6.875,11.500 5.500,11.500 ZM11.500,5.500 L11.500,4.500 C11.500,3.948 11.948,3.500 12.500,3.500 L16.500,3.500 C17.052,3.500 17.500,3.948 17.500,4.500 L17.500,5.500 C16.531,5.500 11.500,5.500 11.500,5.500 M24.500,7.500 L4.500,7.500 C3.948,7.500 3.500,7.948 3.500,8.500 C3.500,9.053 3.948,9.500 4.500,9.500 L24.500,9.500 C25.052,9.500 25.500,9.053 25.500,8.500 C25.500,7.948 25.052,7.500 24.500,7.500 ZM9.500,14.500 C10.052,14.500 10.500,14.948 10.500,15.500 L10.500,25.500 C10.500,26.053 10.052,26.500 9.500,26.500 C8.948,26.500 8.500,26.053 8.500,25.500 L8.500,15.500 C8.500,14.948 8.948,14.500 9.500,14.500 ZM14.500,14.500 C15.052,14.500 15.500,14.948 15.500,15.500 L15.500,25.500 C15.500,26.053 15.052,26.500 14.500,26.500 C13.948,26.500 13.500,26.053 13.500,25.500 L13.500,15.500 C13.500,14.948 13.948,14.500 14.500,14.500 ZM19.500,14.500 C20.052,14.500 20.500,14.948 20.500,15.500 L20.500,25.500 C20.500,26.053 20.052,26.500 19.500,26.500 C18.948,26.500 18.500,26.053 18.500,25.500 L18.500,15.500 C18.500,14.948 18.948,14.500 19.500,14.500 Z"></path>
                                                </svg>

                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-6">
                                        <div className="form-group static">
                                            <label>Date Sent</label>
                                            <div className="text">
                                                {`${new Date(item.dateSent).getDate()}/${new Date(item.dateSent).getMonth()+1}/${new Date(item.dateSent).getFullYear()}`}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-6">
                                        <div className="form-group static">
                                            <label>
                                                Status
                                            </label>
                                            <div className="status-action">
                                            <span className="badge-icon badge-pending">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                                    <g fill="none" fillRule="evenodd">
                                                        <path fill="#FFF" fillOpacity="0" d="M0 0H12V12H0z"></path>
                                                        <path fill="#F59D00"
                                                              d="M6 12c-3.308 0-6-2.692-6-6s2.692-6 6-6 6 2.692 6 6-2.692 6-6 6zM6 .6C3.105.6.6 3.105.6 6c0 2.895 2.505 5.4 5.4 5.4 2.895 0 5.4-2.505 5.4-5.4C11.4 3.105 8.895.6 6 .6zm2.7 5.7H6c-.207 0-.3-.093-.3-.3V2.4c0-.207.093-.3.3-.3s.3.093.3.3v3.3h2.4c.207 0 .3.093.3.3s-.093.3-.3.3z"></path>
                                                    </g>
                                                </svg>

                                                Pending
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-6">
                                        <div className="form-group static">
                                            <label>Sender</label>
                                            <div className="text">
                                                <a className="name" href="#">{fullName}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-xs-12">
                                        <div className="form-group static">
                                            <label>Message</label>
                                            <div className="text">{item.message}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default MyEnquiryPage