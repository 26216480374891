import {BrowserRouter, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import RegistrationPage from "./pages/RegistrationPage";
import DashboardPage from "./pages/DashboardPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TabLayout from "./components/TabLayout";
import Sidebar from "./components/Sidebar";
import Banner from "./components/Banner";
import SchoolSearchPage from "./pages/SchoolSearchPage";
import StudentInformationPage from "./pages/StudentInformationPage";
import ParentInformationPage from "./pages/ParentInformationPage";
import SchoolDetailsPage from "./pages/SchoolDetailsPage";
import MyEnquiryPage from "./pages/MyEnquiryPage";
import MySchoolPage from "./pages/MySchoolPage";
import ProfilePage from "./pages/ProfilePage";
import EditProfilePage from "./pages/EditProfilePage";
import AddParentPage from "./pages/AddParentPage";
import AddChildPage from "./pages/AddChildPage";
import SendEnquiryPage from "./pages/SendEnquiryPage";
import EditApplicantPage from "./pages/EditApplicantPage";
import EditParentPage from "./pages/EditParentPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <HeaderNav />
          <Dashboard />
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/sign-up" element={<SignupPage />} />
          <Route path="/forget-password" element={<ForgotPasswordPage />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/schools/:schoolName" element={<SchoolDetailsPage />} />
          <Route path="/my-enquiry" element={<MyEnquiryPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/profile/edit/:userId" element={<EditProfilePage />} />
          <Route path="/parents/new" element={<AddParentPage />} />
          <Route path="/applicants/new" element={<AddChildPage />} />
          <Route path="/send-enquiry/:schoolName" element={<SendEnquiryPage />} />
          <Route path="/applicants/edit/:learnerId" element={<EditApplicantPage />} />
          <Route path="/parents/edit/:userId" element={<EditParentPage />} />
        </Routes>
          <FooterNav />
      </BrowserRouter>
    </div>
  );
}

function HeaderNav() {
    const location = useLocation()

    const isSignup = location.pathname.startsWith('/sign-up')
    const isRegistration = location.pathname.startsWith('/registration')
    const isAddApplicant = location.pathname.startsWith('/applicants/new')
    const isResetPassword = location.pathname.startsWith('/forget-password')

    const hideNav = location.pathname === '/' || isSignup || isRegistration || isAddApplicant || isResetPassword

    return (
        <div>
            {!hideNav && <Header />}
        </div>
    )
}

function FooterNav() {
    const location = useLocation()

    const isSignup = location.pathname.startsWith('/sign-up')
    const isAddApplicant = location.pathname.startsWith('/applicants/new')
    const isRegistration = location.pathname.startsWith('/registration')
    const isResetPassword = location.pathname.startsWith('/forget-password')

    const hideNav = location.pathname === '/' || isSignup || isAddApplicant || isRegistration || isResetPassword

    return (
        <div>
            {!hideNav && <Footer />}
        </div>
    )

}

function Dashboard() {
    const location = useLocation()

    const hideDashboard = location.pathname.startsWith('/my-application')

    return (
        <div>
            {hideDashboard &&
                <div className="full-container">
                    <Banner />
                    <div className="dashboard-row w-row">
                        {/*<Sidebar />*/}
                        <div className="dashboard-cols w-col w-col-stack">
                            <div className="tabs w-tabs">
                                <TabLayout />
                                <div className="tabs-content w-tab-content">
                                    <Routes>
                                        <Route path="/my-application/learner-information" element={<StudentInformationPage />} />
                                        <Route path="/my-application/parent-information" element={<ParentInformationPage />} />
                                        <Route path="/my-application/schools-search" element={<SchoolSearchPage />} />
                                        <Route path="/my-application/my-schools" element={<MySchoolPage />} />
                                    </Routes>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default App;
