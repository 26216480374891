import React, {useEffect, useState} from "react";
import {useUserContext} from "../context/userContext";
import {onValue, ref} from "firebase/database";
import {db, storage} from "../firebase";
import {getDownloadURL, ref as stRef, uploadBytesResumable} from "firebase/storage";
import UploadProgressDialog from "../dialogs/UploadProgressDialog";
import {school} from "../data/schoolsData";

function Requirements({ item, schoolName }) {
    const [{user, learnerId}, dispatch] = useUserContext()

    const [requirementStatus, setRequirementStatus] = useState(false)
    const [requirementData, setRequirementData] = useState([])
    const [percent, setPercent] = useState(0)
    const [open, setOpen] = useState(false)
    const [notUploaded, setNotUploaded] = useState([])

    useEffect(() => {
        onValue(ref(db, `easyApplications/requirements/${user}/${learnerId}`), snapshot => {
            if (snapshot.exists()) {
                setRequirementData([])

                const data = snapshot.val()

                if (data !== null) {
                    setRequirementStatus(true)
                    Object.values(data).map((item) => {
                        setRequirementData((oldArray) => [...oldArray, item])
                    })
                }
            }
        })


    }, [user])

    useEffect(() => {
        isAllRequirementsUploaded()

    }, [requirementData])

    const isAllRequirementsUploaded = () => {
        const selectSchoolItem = school.filter((search) => search.name === schoolName)
        // A comparer used to determine if two entries are equal.
        const isSameUser = (a, b) => a.category === b.category;

        // Get items that only occur in the left array,
        // using the compareFunction to determine equality.
        const onlyInLeft = (left, right, compareFunction) =>
            left.filter(leftValue =>
                !right.some(rightValue => compareFunction(leftValue, rightValue)));

        const onlyInA = onlyInLeft(selectSchoolItem[0]['requirements'], requirementData, isSameUser);

        const result = [...onlyInA];
        setNotUploaded(result)
        // console.log(onlyInA)
        // setRequirements(result)
        //
        // if (result.length === selectSchoolItem[0]['requirements'].length) {
        //     setRequirementStatus(true)
        // }
    }

    const handleChange = name => event => {
        const storageRef = stRef(storage, `/applicationForm/${user}/requirements/${learnerId}/${name}`)
        const uploadTask = uploadBytesResumable(storageRef, event.target.files[0])

        uploadTask.on("state_changed", snapshot => {
            const percentage = Math.round((snapshot.bytesTransferred/ snapshot.totalBytes) * 100)
            setPercent(percentage)
            setOpen(true)

        }, (error) => {
            alert(error.message)
            setOpen(false)

        }, () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                db.ref('easyApplications').child(`requirements/${user}/${learnerId}/${name}`).update({
                    url: url,
                    category: name,
                    name: event.target.files[0].name
                }).then(() => {
                    setOpen(false)

                }).catch((error) => {
                    alert(error.message)
                    setOpen(false)

                })
            })
        })
    }

    const removeDocument = (category) => {
        db.ref(`easyApplications/requirements/${user}/${learnerId}/${category}`).remove().then(() => {
            alert("Document successfully removed")
        }).catch((err) => {
            alert(err.message)
        })
    }

    return (
        <div>
            {open && <UploadProgressDialog open={open} percent={percent} setOpen={setOpen} />}
            <ul className="school-infos-table info-list-style">
                <li className="requirement-layout">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <i className="info-list-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                <g fill="none" fillRule="evenodd">
                                    <path fill="#FFF" fillOpacity="0" d="M0 0H12V12H0z"></path>
                                    <path fill="#859BBB"
                                          d="M.6 3.64h10.2c.22 0 .6.393.6.6v2.1H9.6c-.22 0-.6.393-.6.6v1.2c0 .206.38.6.6.6h1.8v2.1c0 .206-.38.6-.6.6H1.2c-.221 0-.6-.394-.6-.6v-7.2zm10.8 3.9v-.6H9.6V8.138h1.8v-.6zM10.2.64l-8.1 2.4h8.1V.64zm.9 11.4H.9c-.442 0-.9-.488-.9-.9v-8.1l9.9-3c.188-.047.442-.059.6 0 .206.075.3.394.3.6v2.4c.442 0 1.2.487 1.2.9v7.2c0 .412-.458.9-.9.9z"></path>
                                </g>
                            </svg>

                        </i>
                        <span className="attr">{item.name}</span>
                    </div>
                    <div className="right-block">
                        {requirementData.filter((search) => search.category === item.category).map((requiredItem) => (
                            <div>
                                {/*<span>{requiredItem.name}</span>*/}
                                <button className="btn btn-trash btn-danger -left-margin" onClick={() => removeDocument(requiredItem.category)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid"
                                         width="12" height="12" viewBox="-1 0 31 35">
                                        <path fillRule="evenodd" className="trash-svg" fill="#a6bedc"
                                              d="M25.500,11.500 L25.500,27.500 C25.500,29.709 23.709,31.501 21.500,31.501 L7.500,31.501 C5.291,31.501 3.500,29.709 3.500,27.500 L3.500,11.500 C2.395,11.500 1.500,10.605 1.500,9.500 L1.500,7.500 C1.500,6.395 2.395,5.500 3.500,5.500 L9.500,5.500 L9.500,3.500 C9.500,2.395 10.395,1.500 11.500,1.500 L17.500,1.500 C18.605,1.500 19.500,2.395 19.500,3.500 L19.500,5.500 L25.500,5.500 C26.605,5.500 27.500,6.395 27.500,7.500 L27.500,9.500 C27.500,10.605 26.605,11.500 25.500,11.500 ZM5.500,11.500 L5.500,27.500 C5.500,28.605 6.395,29.501 7.500,29.501 L21.500,29.501 C22.605,29.501 23.500,28.605 23.500,27.500 L23.500,11.500 C22.375,11.500 6.875,11.500 5.500,11.500 ZM11.500,5.500 L11.500,4.500 C11.500,3.948 11.948,3.500 12.500,3.500 L16.500,3.500 C17.052,3.500 17.500,3.948 17.500,4.500 L17.500,5.500 C16.531,5.500 11.500,5.500 11.500,5.500 M24.500,7.500 L4.500,7.500 C3.948,7.500 3.500,7.948 3.500,8.500 C3.500,9.053 3.948,9.500 4.500,9.500 L24.500,9.500 C25.052,9.500 25.500,9.053 25.500,8.500 C25.500,7.948 25.052,7.500 24.500,7.500 ZM9.500,14.500 C10.052,14.500 10.500,14.948 10.500,15.500 L10.500,25.500 C10.500,26.053 10.052,26.500 9.500,26.500 C8.948,26.500 8.500,26.053 8.500,25.500 L8.500,15.500 C8.500,14.948 8.948,14.500 9.500,14.500 ZM14.500,14.500 C15.052,14.500 15.500,14.948 15.500,15.500 L15.500,25.500 C15.500,26.053 15.052,26.500 14.500,26.500 C13.948,26.500 13.500,26.053 13.500,25.500 L13.500,15.500 C13.500,14.948 13.948,14.500 14.500,14.500 ZM19.500,14.500 C20.052,14.500 20.500,14.948 20.500,15.500 L20.500,25.500 C20.500,26.053 20.052,26.500 19.500,26.500 C18.948,26.500 18.500,26.053 18.500,25.500 L18.500,15.500 C18.500,14.948 18.948,14.500 19.500,14.500 Z"></path>
                                    </svg>

                                    Remove Document
                                </button>
                            </div>
                        ))}
                        {notUploaded.filter((search) => search.category === item.category).map((requiredItem) => (
                            <input type="file" className="btn btn-default" onChange={handleChange(requiredItem.category)} />
                        ))}
                        {/*{(() => {*/}
                        {/*    if (requirementData.length === 0) {*/}
                        {/*        return (*/}
                        {/*            <input type="file" className="btn btn-default" onChange={handleChange(item.category)} />*/}
                        {/*        )*/}
                        {/*    }*/}
                        {/*})()}*/}
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default Requirements