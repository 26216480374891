import React from "react";
import {useParams} from "react-router-dom";
import {db} from "../firebase";
import {useUserContext} from "../context/userContext";
import SEO from "../seo/SEO";
import schoolData from "../data/private_college.json"

function SchoolDetailsPage() {
    const [{user}, dispatch] = useUserContext()
    const {schoolName} = useParams()

    const addSchool = (accreditationNo, physicalAddress, schoolPhase) => {

        if (user) {
            db.ref('easyApplications').child(`mySchool/${user}/${accreditationNo}`).set({
                schoolName: schoolName,
                physicalAddress: physicalAddress,
                schoolPhase: schoolPhase,
                accreditationNo: accreditationNo,
                schoolId: accreditationNo

            }, () => {
                alert("School successfully added to my schools!")

            }).catch((e) => {
                alert(e.message)

            })
        } else {
            alert("Sign in first")
        }
    }

    return (
        <main>
            <SEO title={`${schoolName} | Easy Applications`}
                 description="Easy Applications is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://apply.easyapplications.co.za" />
            <div className="content-wrapper" style={{ textAlign: "left" }}>
                <div id="flash-area"></div>
                <div className="container-fluid school-profile-main">
                    <div className="hidden-xs">
                        <div className="row">
                            <div className="col-lg-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="/dashboard">Home</a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="/my-application/schools-search">School Search</a>
                                        </li>
                                        <li className="breadcrumb-item active">{schoolName}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    {schoolData.school.filter((search) => search.name === schoolName).map((item) => (
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="columns">
                                    <div className="left-content column">
                                        <div className="content-block school-profile">
                                            <div className="school-profile-header">
                                                <div className="bar bar-primary bar-inset">
                                                    <div className="title-wrap">
                                                        <div className="logo-wrapper visible-xs">
                                                            <img className="school-logo"
                                                                 src="https://directory-ca-prod.s3.amazonaws.com/schools/1011/logo/main_school_logo.png"  alt=""/>
                                                        </div>
                                                        <div className="title">
                                                            <h5 className="school-title-location visible-xs">
                                                                <span>{item.physical_address}</span>
                                                                <span className="za country-flag flag iti-flag"></span>
                                                            </h5>
                                                            {item.name}
                                                        </div>
                                                    </div>
                                                    <button className="btn btn-primary" style={{ float: "right" }}
                                                            onClick={() => addSchool(item.accreditation_no, item.physical_address, item.schoolPhase)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                                            <g fill="none" fillRule="evenodd">
                                                                <path fill="#FFF" fillOpacity="0" d="M0 0H12V12H0z"></path>
                                                                <path fill="#859BBB"
                                                                      d="M6 0c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6C.001 2.687 2.687.001 6 0zm0 .8C3.128.8.8 3.128.8 6c0 2.872 2.328 5.2 5.2 5.2 2.872 0 5.2-2.328 5.2-5.2C11.2 3.128 8.872.8 6 .8zm0 1.6c.22 0 .4.18.4.4v2.8h2.8c.22 0 .4.18.4.4 0 .22-.18.4-.4.4H6.4v2.8c0 .22-.18.4-.4.4-.22 0-.4-.18-.4-.4V6.4H2.8c-.22 0-.4-.18-.4-.4 0-.22.18-.4.4-.4h2.8V2.8c0-.22.18-.4.4-.4z"></path>
                                                            </g>
                                                        </svg>

                                                        Add School
                                                    </button>
                                                </div>
                                                <div className="school-logo-title-block">
                                                    <div className="school-photo school-carousel-wrap">
                                                        <div className="school-carousel carousel-fade" data-ride="carousel">
                                                            <div className="carousel-inner" role="listbox">
                                                                <div className="item active">
                                                                    <div className="item-photo"
                                                                         style={{background: `url('https://directory-ca-prod.s3.amazonaws.com/schools/1011/photos/2142/compressed_70398-3.jpg') no-repeat center center`,  backgroundSize: "cover"}}>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="school-profile-logo-block hidden-xs">
                                                        <div className="logo-wrapper">
                                                            <img className="school-logo"
                                                                 src="https://directory-ca-prod.s3.amazonaws.com/schools/1011/logo/main_school_logo.png" />
                                                        </div>
                                                        <div className="title-wrapper">
                                                            <h5 className="school-title-location">
                                                                <span className="coma">{item.physical_address}</span>
                                                                <span>{item.province}</span>
                                                                <span className="za country-flag flag hidden-xs iti-flag"></span>
                                                            </h5>
                                                            <h3>{item.name}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="res-content">
                                                <article className="school-description-block">
                                                    <h4>Description</h4>
                                                </article>
                                                <div className="school-data-info-block">
                                                    <ul className="school-info-list info-list-style">
                                                        <li>
                                                            <i className="info-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                     height="12" viewBox="0 0 12 12">
                                                                    <g fill="none" fillRule="evenodd">
                                                                        <path fill="#FFF" fillOpacity="0"
                                                                              d="M0 0H12V12H0z"></path>
                                                                        <path fill="#859BBB"
                                                                              d="M.6 3.64h10.2c.22 0 .6.393.6.6v2.1H9.6c-.22 0-.6.393-.6.6v1.2c0 .206.38.6.6.6h1.8v2.1c0 .206-.38.6-.6.6H1.2c-.221 0-.6-.394-.6-.6v-7.2zm10.8 3.9v-.6H9.6V8.138h1.8v-.6zM10.2.64l-8.1 2.4h8.1V.64zm.9 11.4H.9c-.442 0-.9-.488-.9-.9v-8.1l9.9-3c.188-.047.442-.059.6 0 .206.075.3.394.3.6v2.4c.442 0 1.2.487 1.2.9v7.2c0 .412-.458.9-.9.9z"></path>
                                                                    </g>
                                                                </svg>
                                                            </i>
                                                            <span className="attr">School tuition</span>
                                                            <span className="val">
                                                                {(() => {
                                                                    if (item.hasOwnProperty('schoolFees')) {
                                                                        return (
                                                                            `R${item.schoolFees[0].fee} ~ R${item.schoolFees[item.schoolFees.length - 1].fee}`
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            "No information"
                                                                        )
                                                                    }
                                                                })()}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <i className="info-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                     height="12" viewBox="0 0 12 12">
                                                                    <g fill="none" fillRule="evenodd">
                                                                        <path fill="#FFF" fillOpacity="0"
                                                                              d="M0 0H12V12H0z"></path>
                                                                        <path fill="#859BBB"
                                                                              d="M6.14 0l5.64 2.496v7.008L6.14 12 .5 9.492V2.496L6.14 0zM1.1 3.48v5.64l4.692 2.022V5.167L1.1 3.48zm10.08 0L6.5 5.163v5.974l4.68-2.017V3.48zM6.14.708L1.208 2.82 6.14 4.584l4.932-1.764L6.14.708z"></path>
                                                                    </g>
                                                                </svg>
                                                            </i>
                                                            <span className="attr">Grades available</span>
                                                            <span className="val">
                                                                {(() => {
                                                                    if (item.hasOwnProperty('schoolFees')) {
                                                                        return (
                                                                            `Grd ${item.lowestGrade} ~ Grd ${item.highestGrade}`
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            "No information"
                                                                        )
                                                                    }
                                                                })()}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <i className="info-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                     height="12" viewBox="0 0 12 12">
                                                                    <g fill="none" fillRule="evenodd">
                                                                        <path fill="#FFF" fillOpacity="0"
                                                                              d="M0 0H12V12H0z"></path>
                                                                        <path fill="#859BBB"
                                                                              d="M.6 3.64h10.2c.22 0 .6.393.6.6v2.1H9.6c-.22 0-.6.393-.6.6v1.2c0 .206.38.6.6.6h1.8v2.1c0 .206-.38.6-.6.6H1.2c-.221 0-.6-.394-.6-.6v-7.2zm10.8 3.9v-.6H9.6V8.138h1.8v-.6zM10.2.64l-8.1 2.4h8.1V.64zm.9 11.4H.9c-.442 0-.9-.488-.9-.9v-8.1l9.9-3c.188-.047.442-.059.6 0 .206.075.3.394.3.6v2.4c.442 0 1.2.487 1.2.9v7.2c0 .412-.458.9-.9.9z"></path>
                                                                    </g>
                                                                </svg>

                                                            </i>
                                                            <span className="attr">Scholarships Available</span>
                                                            <span className="val">
                                                                {item.hasOwnProperty('scholarship') ? item.scholarship : "No Information"}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <i className="info-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                     height="12" viewBox="0 0 12 12">
                                                                    <g fill="none" fillRule="evenodd">
                                                                        <path fill="#FFF" fillOpacity="0"
                                                                              d="M0 0H12V12H0z"></path>
                                                                        <path fill="#859BBB"
                                                                              d="M10.364 9H8.97c-.024-.188-.214-.425-.27-.6h1.5c.602 0 1.2-.298 1.2-.9 0-.536-.632-1.415-1.142-1.508.022-.12.311-.567.386-.453C11.261 5.773 12 6.802 12 7.5c0 .904-.733 1.5-1.636 1.5zM8.7 6.6c-.355 0-.663-.157-.968-.337.075-.166.142-.337.2-.513.242.159.484.25.768.25 1.055 0 1.8-1.194 1.8-2.7 0-1.506-.745-2.7-1.8-2.7-.527 0-.977.251-1.323.745-.101-.16-.208-.316-.325-.46C7.491.338 8.052 0 8.7 0c1.356 0 2.373 1.493 2.4 3.3.029 1.905-1.044 3.3-2.4 3.3zM4.2 0C2.7 0 .925 1.356.9 3.9.877 6.235 2.317 8.4 4.2 8.4c1.883 0 3.322-2.165 3.3-4.5C7.475 1.244 5.7 0 4.2 0zm0 7.8c-1.579 0-2.7-1.87-2.7-3.9C1.5 1.8 3.074.6 4.2.6c1.127 0 2.7 1.2 2.7 3.3 0 2.03-1.12 3.9-2.7 3.9zm-2.4-.6C1.171 7.55.6 8.831.6 9.6c0 1.128.972 1.8 2.1 1.8h3c1.128 0 2.1-.672 2.1-1.8 0-.769-.57-2.05-1.2-2.4-.175-.026.191-.45.3-.6.752.453 1.5 2.058 1.5 3 0 1.431-1.106 2.4-2.536 2.4H2.59C1.16 12 0 11.031 0 9.6c0-.942.748-2.547 1.5-3 .109.15.45.515.3.6z"></path>
                                                                    </g>
                                                                </svg>
                                                            </i>
                                                            <span className="attr">School Phase</span>
                                                            <span className="val">
                                                                {item.hasOwnProperty("schoolPhase") ? item.schoolPhase : "No information"}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <ul className="school-info-list info-list-style">
                                                        <li>
                                                            <i className="info-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                     height="12" viewBox="0 0 12 12">
                                                                    <g fill="none" fillRule="evenodd">
                                                                        <path fill="#FFF" fillOpacity="0"
                                                                              d="M0 0H12V12H0z"></path>
                                                                        <path fill="#859BBB"
                                                                              d="M.6 3.64h10.2c.22 0 .6.393.6.6v2.1H9.6c-.22 0-.6.393-.6.6v1.2c0 .206.38.6.6.6h1.8v2.1c0 .206-.38.6-.6.6H1.2c-.221 0-.6-.394-.6-.6v-7.2zm10.8 3.9v-.6H9.6V8.138h1.8v-.6zM10.2.64l-8.1 2.4h8.1V.64zm.9 11.4H.9c-.442 0-.9-.488-.9-.9v-8.1l9.9-3c.188-.047.442-.059.6 0 .206.075.3.394.3.6v2.4c.442 0 1.2.487 1.2.9v7.2c0 .412-.458.9-.9.9z"></path>
                                                                    </g>
                                                                </svg>

                                                            </i>
                                                            <span className="attr">Boarding Day</span>
                                                            {(() => {
                                                                if (item.hasOwnProperty('isBoarding')) {
                                                                    if (item.isBoarding === 'Yes') {
                                                                        return (
                                                                            <span className="val">
                                                                            Day and Boarding
                                                                        </span>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <span className="val">
                                                                            Day
                                                                        </span>
                                                                        )
                                                                    }
                                                                } else {
                                                                    return (
                                                                        "No information"
                                                                    )
                                                                }

                                                            })()}

                                                        </li>
                                                        <li>
                                                            <i className="info-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                     height="12" viewBox="0 0 12 12">
                                                                    <g fill="none" fillRule="evenodd">
                                                                        <path fill="#FFF" fillOpacity="0"
                                                                              d="M0 0H12V12H0z"></path>
                                                                        <path fill="#859BBB"
                                                                              d="M6.14 0l5.64 2.496v7.008L6.14 12 .5 9.492V2.496L6.14 0zM1.1 3.48v5.64l4.692 2.022V5.167L1.1 3.48zm10.08 0L6.5 5.163v5.974l4.68-2.017V3.48zM6.14.708L1.208 2.82 6.14 4.584l4.932-1.764L6.14.708z"></path>
                                                                    </g>
                                                                </svg>

                                                            </i>
                                                            <span className="attr">Curriculum</span>
                                                            <span className="val">
                                                                {item.hasOwnProperty('curriculum') ? item.curriculum : "No information"}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <i className="info-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                     height="12" viewBox="0 0 12 12">
                                                                    <g fill="none" fillRule="evenodd">
                                                                        <path fill="#FFF" fillOpacity="0"
                                                                              d="M0 0H12V12H0z"></path>
                                                                        <path fill="#859BBB"
                                                                              d="M4 12c-.376-.042-.718-.238-.945-.541-.227-.303-.32-.686-.255-1.059l.4-1.6c-.02-.272-.296-.552-.4-.4-.258.435-.7.729-1.2.8-.824 0-1.6-.968-1.6-2s.776-2 1.6-2c.5.073.94.366 1.2.8.116.168.372.248.4 0l-.4-1.6c-.132-.812.792-1.696 1.6-1.6l1.6.4c.856.036.068-.692.224-.592-.382-.191-.623-.581-.624-1.008 0-.816.956-1.6 2-1.6s2 .784 2 1.6c-.07.501-.364.943-.8 1.2-.168.112-.004.424.4.4l1.6-.4c.744-.092 1.148.5 1.148 1.232L12 6.4c-.066.187-.213.334-.4.4-.345.01-.677-.127-.916-.376-.157-.233-.406-.387-.684-.424-.464 0-.8.528-.8 1.2 0 .672.336 1.2.8 1.2.254.019.504-.075.684-.256.224-.293.552-.487.916-.544.186.068.332.214.4.4v2.8c0 .848-.8 1.2-1.6 1.2H4zm-.8-4.4c.476 0 .8.732.8 1.2-.008.124-.4 1.6-.4 1.6.01.437.363.79.8.8h6c.215.01.424-.072.576-.224.152-.152.233-.361.224-.576.04-.533.04-1.067 0-1.6-.168-.04-.76.4-1.2.4-.824 0-1.6-.968-1.6-2s.776-2 1.6-2c.417.076.82.21 1.2.4-.004-.516 0-1.6 0-1.6 0-.352-.448-.44-.8-.4 0 0-1.556.432-1.6.4-.215.01-.425-.07-.577-.223C8.071 3.625 7.99 3.415 8 3.2c0 0-.148-.808.316-1.116.244-.16.484-.264.484-.484 0-.46-.52-.8-1.2-.8-.68 0-1.2.34-1.2.8 0 .22.156.24.4.4.46.308.4 1.2.4 1.2.01.215-.07.425-.223.577-.152.152-.362.233-.577.223-.674-.096-1.341-.23-2-.4-.44.004-.796.36-.8.8 0 0 .264.928.4 1.6.01.215-.07.425-.223.577-.152.152-.362.233-.577.223 0 0-.784.084-1.096-.376C2.027 6.2 1.834 6.037 1.6 6c-.464 0-.8.528-.8 1.2 0 .672.336 1.2.8 1.2.205.025.406-.076.508-.256.286-.308.674-.501 1.092-.544z"></path>
                                                                    </g>
                                                                </svg>

                                                            </i>
                                                            <span className="attr">Category</span>
                                                            <span className="val">
                                                                {item.hasOwnProperty('category') ? item.category : "No information"}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <i className="info-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                     height="12" viewBox="0 0 12 12">
                                                                    <g fill="none" fillRule="evenodd">
                                                                        <path fill="#FFF" fillOpacity="0"
                                                                              d="M0 0H12V12H0z"></path>
                                                                        <path fill="#859BBB"
                                                                              d="M6.14 0l5.64 2.496v7.008L6.14 12 .5 9.492V2.496L6.14 0zM1.1 3.48v5.64l4.692 2.022V5.167L1.1 3.48zm10.08 0L6.5 5.163v5.974l4.68-2.017V3.48zM6.14.708L1.208 2.82 6.14 4.584l4.932-1.764L6.14.708z"></path>
                                                                    </g>
                                                                </svg>

                                                            </i>
                                                            <span className="attr">Accreditation Number</span>
                                                            <span className="val">{item.accreditation_no}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <hr className="res-content-separate" />
                                                <h3>
                                                    Banking Details
                                                </h3>
                                                {(() => {
                                                    if (item.hasOwnProperty('bankingDetails')) {
                                                        item.bankingDetails.map((bankDetails) => (
                                                            <div className="school-data-info-block">
                                                                <ul className="school-infos-table info-list-style">
                                                                    <li>
                                                                        <i className="info-list-icon">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                                 height="12" viewBox="0 0 12 12">
                                                                                <g fill="none" fillRule="evenodd">
                                                                                    <path fill="#FFF" fillOpacity="0"
                                                                                          d="M0 0H12V12H0z"></path>
                                                                                    <path fill="#859BBB"
                                                                                          d="M10.364 9H8.97c-.024-.188-.214-.425-.27-.6h1.5c.602 0 1.2-.298 1.2-.9 0-.536-.632-1.415-1.142-1.508.022-.12.311-.567.386-.453C11.261 5.773 12 6.802 12 7.5c0 .904-.733 1.5-1.636 1.5zM8.7 6.6c-.355 0-.663-.157-.968-.337.075-.166.142-.337.2-.513.242.159.484.25.768.25 1.055 0 1.8-1.194 1.8-2.7 0-1.506-.745-2.7-1.8-2.7-.527 0-.977.251-1.323.745-.101-.16-.208-.316-.325-.46C7.491.338 8.052 0 8.7 0c1.356 0 2.373 1.493 2.4 3.3.029 1.905-1.044 3.3-2.4 3.3zM4.2 0C2.7 0 .925 1.356.9 3.9.877 6.235 2.317 8.4 4.2 8.4c1.883 0 3.322-2.165 3.3-4.5C7.475 1.244 5.7 0 4.2 0zm0 7.8c-1.579 0-2.7-1.87-2.7-3.9C1.5 1.8 3.074.6 4.2.6c1.127 0 2.7 1.2 2.7 3.3 0 2.03-1.12 3.9-2.7 3.9zm-2.4-.6C1.171 7.55.6 8.831.6 9.6c0 1.128.972 1.8 2.1 1.8h3c1.128 0 2.1-.672 2.1-1.8 0-.769-.57-2.05-1.2-2.4-.175-.026.191-.45.3-.6.752.453 1.5 2.058 1.5 3 0 1.431-1.106 2.4-2.536 2.4H2.59C1.16 12 0 11.031 0 9.6c0-.942.748-2.547 1.5-3 .109.15.45.515.3.6z"></path>
                                                                                </g>
                                                                            </svg>

                                                                        </i>
                                                                        <span className="attr">Account Name</span>
                                                                        <span className="val">{bankDetails.accountName}</span>
                                                                    </li>
                                                                    <li>
                                                                        <i className="info-list-icon">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                                 height="12" viewBox="0 0 12 12">
                                                                                <g fill="none" fillRule="evenodd">
                                                                                    <path fill="#FFF" fillOpacity="0"
                                                                                          d="M0 0H12V12H0z"></path>
                                                                                    <path fill="#859BBB"
                                                                                          d="M10.364 9H8.97c-.024-.188-.214-.425-.27-.6h1.5c.602 0 1.2-.298 1.2-.9 0-.536-.632-1.415-1.142-1.508.022-.12.311-.567.386-.453C11.261 5.773 12 6.802 12 7.5c0 .904-.733 1.5-1.636 1.5zM8.7 6.6c-.355 0-.663-.157-.968-.337.075-.166.142-.337.2-.513.242.159.484.25.768.25 1.055 0 1.8-1.194 1.8-2.7 0-1.506-.745-2.7-1.8-2.7-.527 0-.977.251-1.323.745-.101-.16-.208-.316-.325-.46C7.491.338 8.052 0 8.7 0c1.356 0 2.373 1.493 2.4 3.3.029 1.905-1.044 3.3-2.4 3.3zM4.2 0C2.7 0 .925 1.356.9 3.9.877 6.235 2.317 8.4 4.2 8.4c1.883 0 3.322-2.165 3.3-4.5C7.475 1.244 5.7 0 4.2 0zm0 7.8c-1.579 0-2.7-1.87-2.7-3.9C1.5 1.8 3.074.6 4.2.6c1.127 0 2.7 1.2 2.7 3.3 0 2.03-1.12 3.9-2.7 3.9zm-2.4-.6C1.171 7.55.6 8.831.6 9.6c0 1.128.972 1.8 2.1 1.8h3c1.128 0 2.1-.672 2.1-1.8 0-.769-.57-2.05-1.2-2.4-.175-.026.191-.45.3-.6.752.453 1.5 2.058 1.5 3 0 1.431-1.106 2.4-2.536 2.4H2.59C1.16 12 0 11.031 0 9.6c0-.942.748-2.547 1.5-3 .109.15.45.515.3.6z"></path>
                                                                                </g>
                                                                            </svg>

                                                                        </i>
                                                                        <span className="attr">Account Number</span>
                                                                        <span className="val">{bankDetails.accountNumber}</span>
                                                                    </li>
                                                                    <li>
                                                                        <i className="info-list-icon">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                                 height="12" viewBox="0 0 12 12">
                                                                                <g fill="none" fillRule="evenodd">
                                                                                    <path fill="#FFF" fillOpacity="0"
                                                                                          d="M0 0H12V12H0z"></path>
                                                                                    <path fill="#859BBB"
                                                                                          d="M10.364 9H8.97c-.024-.188-.214-.425-.27-.6h1.5c.602 0 1.2-.298 1.2-.9 0-.536-.632-1.415-1.142-1.508.022-.12.311-.567.386-.453C11.261 5.773 12 6.802 12 7.5c0 .904-.733 1.5-1.636 1.5zM8.7 6.6c-.355 0-.663-.157-.968-.337.075-.166.142-.337.2-.513.242.159.484.25.768.25 1.055 0 1.8-1.194 1.8-2.7 0-1.506-.745-2.7-1.8-2.7-.527 0-.977.251-1.323.745-.101-.16-.208-.316-.325-.46C7.491.338 8.052 0 8.7 0c1.356 0 2.373 1.493 2.4 3.3.029 1.905-1.044 3.3-2.4 3.3zM4.2 0C2.7 0 .925 1.356.9 3.9.877 6.235 2.317 8.4 4.2 8.4c1.883 0 3.322-2.165 3.3-4.5C7.475 1.244 5.7 0 4.2 0zm0 7.8c-1.579 0-2.7-1.87-2.7-3.9C1.5 1.8 3.074.6 4.2.6c1.127 0 2.7 1.2 2.7 3.3 0 2.03-1.12 3.9-2.7 3.9zm-2.4-.6C1.171 7.55.6 8.831.6 9.6c0 1.128.972 1.8 2.1 1.8h3c1.128 0 2.1-.672 2.1-1.8 0-.769-.57-2.05-1.2-2.4-.175-.026.191-.45.3-.6.752.453 1.5 2.058 1.5 3 0 1.431-1.106 2.4-2.536 2.4H2.59C1.16 12 0 11.031 0 9.6c0-.942.748-2.547 1.5-3 .109.15.45.515.3.6z"></path>
                                                                                </g>
                                                                            </svg>

                                                                        </i>
                                                                        <span className="attr">Bank Name</span>
                                                                        <span className="val">{bankDetails.bank}</span>
                                                                    </li>
                                                                </ul>
                                                                <ul className="school-infos-table info-list-style">
                                                                    <li>
                                                                        <i className="info-list-icon">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                                 height="12" viewBox="0 0 12 12">
                                                                                <g fill="none" fillRule="evenodd">
                                                                                    <path fill="#FFF" fillOpacity="0"
                                                                                          d="M0 0H12V12H0z"></path>
                                                                                    <path fill="#859BBB"
                                                                                          d="M.6 3.64h10.2c.22 0 .6.393.6.6v2.1H9.6c-.22 0-.6.393-.6.6v1.2c0 .206.38.6.6.6h1.8v2.1c0 .206-.38.6-.6.6H1.2c-.221 0-.6-.394-.6-.6v-7.2zm10.8 3.9v-.6H9.6V8.138h1.8v-.6zM10.2.64l-8.1 2.4h8.1V.64zm.9 11.4H.9c-.442 0-.9-.488-.9-.9v-8.1l9.9-3c.188-.047.442-.059.6 0 .206.075.3.394.3.6v2.4c.442 0 1.2.487 1.2.9v7.2c0 .412-.458.9-.9.9z"></path>
                                                                                </g>
                                                                            </svg>

                                                                        </i>
                                                                        <span className="attr">Branch</span>
                                                                        <span className="val">{bankDetails.branch}</span>
                                                                    </li>
                                                                    <li>
                                                                        <i className="info-list-icon">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                                 height="12" viewBox="0 0 12 12">
                                                                                <g fill="none" fillRule="evenodd">
                                                                                    <path fill="#FFF" fillOpacity="0"
                                                                                          d="M0 0H12V12H0z"></path>
                                                                                    <path fill="#859BBB"
                                                                                          d="M.6 3.64h10.2c.22 0 .6.393.6.6v2.1H9.6c-.22 0-.6.393-.6.6v1.2c0 .206.38.6.6.6h1.8v2.1c0 .206-.38.6-.6.6H1.2c-.221 0-.6-.394-.6-.6v-7.2zm10.8 3.9v-.6H9.6V8.138h1.8v-.6zM10.2.64l-8.1 2.4h8.1V.64zm.9 11.4H.9c-.442 0-.9-.488-.9-.9v-8.1l9.9-3c.188-.047.442-.059.6 0 .206.075.3.394.3.6v2.4c.442 0 1.2.487 1.2.9v7.2c0 .412-.458.9-.9.9z"></path>
                                                                                </g>
                                                                            </svg>

                                                                        </i>
                                                                        <span className="attr">Branch Code</span>
                                                                        <span className="val">{bankDetails.branchCode}</span>
                                                                    </li>
                                                                    <li>
                                                                        <i className="info-list-icon">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                                 height="12" viewBox="0 0 12 12">
                                                                                <g fill="none" fillRule="evenodd">
                                                                                    <path fill="#FFF" fillOpacity="0"
                                                                                          d="M0 0H12V12H0z"></path>
                                                                                    <path fill="#859BBB"
                                                                                          d="M.6 3.64h10.2c.22 0 .6.393.6.6v2.1H9.6c-.22 0-.6.393-.6.6v1.2c0 .206.38.6.6.6h1.8v2.1c0 .206-.38.6-.6.6H1.2c-.221 0-.6-.394-.6-.6v-7.2zm10.8 3.9v-.6H9.6V8.138h1.8v-.6zM10.2.64l-8.1 2.4h8.1V.64zm.9 11.4H.9c-.442 0-.9-.488-.9-.9v-8.1l9.9-3c.188-.047.442-.059.6 0 .206.075.3.394.3.6v2.4c.442 0 1.2.487 1.2.9v7.2c0 .412-.458.9-.9.9z"></path>
                                                                                </g>
                                                                            </svg>

                                                                        </i>
                                                                        <span className="attr">Reference</span>
                                                                        <span className="val">{bankDetails.reference}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        ))
                                                    } else {
                                                        return (
                                                            "No information"
                                                        )
                                                    }
                                                })()}
                                                <hr className="res-content-separate" />
                                                <h3>School Fees</h3>
                                                <div className="school-infos school-data-info-block" style={{ flexWrap: "wrap" }}>
                                                    {(() => {
                                                        if (item.hasOwnProperty('schoolFees')) {
                                                            item.schoolFees.map((schoolFee) => (
                                                                <ul className="school-infos-table info-list-style" style={{ width: "auto", marginLeft: 0, marginTop: 0 }}>
                                                                    <li>
                                                                        <i className="info-list-icon">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                                 height="12" viewBox="0 0 12 12">
                                                                                <g fill="none" fillRule="evenodd">
                                                                                    <path fill="#FFF" fillOpacity="0"
                                                                                          d="M0 0H12V12H0z"></path>
                                                                                    <path fill="#859BBB"
                                                                                          d="M2.64 0c-.43-.008-.846.156-1.155.457-.308.3-.483.713-.485 1.143v8.8c-.007.426.159.838.46 1.14.302.301.714.467 1.14.46h8.8V0H2.64zm7.96 11.2h-8c-.442 0-.8-.358-.8-.8 0-.442.358-.8.8-.8h8v1.6zm0-2.4h-8c-.28.105-.548.239-.8.4V1.6c.095-.396.404-.705.8-.8h8v8z"></path>
                                                                                </g>
                                                                            </svg>

                                                                        </i>
                                                                        <span className="attr">Grade {schoolFee.grade}</span>
                                                                        <span className="val">R{schoolFee.fee}</span>
                                                                    </li>
                                                                </ul>
                                                            ))
                                                        } else {
                                                            return (
                                                                "No information"
                                                            )
                                                        }
                                                    })()}
                                                </div>
                                                <hr className="res-content-separate hidden-xs" />
                                                <h3>Requirements</h3>
                                                <div className="school-infos school-data-info-block" style={{flexWrap: "wrap"}}>
                                                    {(() => {
                                                        if (item.hasOwnProperty('requirements')) {
                                                            item.requirements.map((requiredItem) => (
                                                                <ul className="school-infos-table info-list-style" style={{margin: 0}}>
                                                                    <li>
                                                                        <i className="info-list-icon">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                 width="12" height="12" viewBox="0 0 12 12">
                                                                                <g fill="none" fillRule="evenodd">
                                                                                    <path fill="#FFF" fillOpacity="0"
                                                                                          d="M0 0H12V12H0z"></path>
                                                                                    <path fill="#859BBB"
                                                                                          d="M10.002 9H7.77c-.407 0-.6-.186-.6-.6v-.6h-3.6v3.6c0 .414-.44.6-.864.6h-.768c-.424 0-.768-.186-.768-.6V.75c0-.414.344-.75.768-.75h.768c.424 0 .864.186.864.6v.6h3.3c.424 0 .9.186.9.6v.9h2.1c.424 0 .9.26.9.675V8.25c0 .414-.344.75-.768.75zM2.97.9c0-.207-.388-.3-.6-.3-.212 0-.6.093-.6.3v10.2c0 .207.388.3.6.3.212 0 .6-.093.6-.3V.9zm.6.9h3c.212 0 .6.093.6.3v4.8c0 .207-.388.3-.6.3h-3V1.8zm4.2 6.6h1.8c.212 0 .6-.093.6-.3V3.6c0-.207-.388-.3-.6-.3h-1.8v5.1z"></path>
                                                                                </g>
                                                                            </svg>

                                                                        </i>
                                                                        <span className="attr">{requiredItem.name}</span>
                                                                        <span className="val">Yes</span>
                                                                    </li>
                                                                </ul>
                                                            ))
                                                        } else {
                                                            return (
                                                                "No information"
                                                            )
                                                        }
                                                    })()}
                                                </div>
                                                <hr className="res-content-seperate"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-sidebar column">
                                        <div className="content-block campus-wrapper">
                                            <div className="res-content">
                                                <h5 className="campus-contact-title">{item.name}</h5>
                                                <dl className="campus-contact-list">
                                                    <dt>Head of School</dt>
                                                    <dd style={{ marginBottom: 16 }}>
                                                        {item.hasOwnProperty('principal') ? item.principal : "No information"}
                                                    </dd>
                                                    <div className="campus-contact-address">
                                                        <dt>Address</dt>
                                                        <dd style={{ marginBottom: 16 }}>
                                                            {item.physical_address}
                                                        </dd>
                                                    </div>
                                                    <dt>Province</dt>
                                                    <dd style={{ marginBottom: 16 }}>
                                                        {item.province}
                                                    </dd>
                                                    <dt>Country</dt>
                                                    <dd style={{ marginBottom: 16 }}>
                                                        South Africa
                                                    </dd>
                                                    <dt>Admissions Contact</dt>
                                                    <dd style={{ marginBottom: 16 }}>
                                                        Admissions Office
                                                    </dd>
                                                    <dt>Admissions Phone Number</dt>
                                                    <dd style={{ marginBottom: 16 }}>
                                                        {item.telephone}
                                                    </dd>
                                                    <dt>Admissions Email</dt>
                                                    <dd style={{ marginBottom: 16 }}>
                                                        <a href={`mailto:${item.email}`}>{item.email}</a>
                                                    </dd>
                                                    <dt>School Website</dt>
                                                    <dd style={{ marginBottom: 16 }}>
                                                        <a target="_blank" rel="noopener noreferrer" href="#">
                                                            No information
                                                        </a>
                                                    </dd>
                                                </dl>
                                            </div>
                                            <ul className="social-links-list">
                                                <li className="social-links-fb">
                                                    <a target="_blank" rel="noopener noreferrer" href="//www.facebook.com/">
                                                        facebook
                                                    </a>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </main>
    )
}

export default SchoolDetailsPage