import React, {useEffect, useState} from "react";
import {useUserContext} from "../context/userContext";
import {onValue, ref} from "firebase/database";
import {db} from "../firebase";
import imgPerson from "../img/ic-person.png";
import SEO from "../seo/SEO";

function StudentInformationPage() {
    const [{user, learner}, dispatch] =  useUserContext()

    const [learnerData, setLearnerData] = useState([])

    useEffect(() => {
        onValue(ref(db, `easyApplications/profile/${user}/studentInfo`), snapshot => {
            if (snapshot.exists()) {
                setLearnerData([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((item) => {
                        setLearnerData((oldArray) => [...oldArray, item])
                    })
                }
            }
        })
    }, [user])

    return (
        <main className="-no-padding-top">
            <SEO title="Applicant Information | Easy Applications"
                 description="Easy Applications is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://apply.easyapplications.co.za" />
            <div className="content-wrapper" style={{ textAlign: "left"}}>
                <div className="bar title bar-inset clearfix bar-top">
                    <div className="title">
                        Applicant
                    </div>
                    <div className="right-block add-parent-wrapper">
                        <a className="btn btn-primary add-parent js-add-parent item add_fields" href="/applicants/new">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                <g fill="none" fillRule="evenodd">
                                    <path fill="#FFF" fillOpacity="0" d="M0 0H12V12H0z"></path>
                                    <path fill="#859BBB"
                                          d="M6 0c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6C.001 2.687 2.687.001 6 0zm0 .8C3.128.8.8 3.128.8 6c0 2.872 2.328 5.2 5.2 5.2 2.872 0 5.2-2.328 5.2-5.2C11.2 3.128 8.872.8 6 .8zm0 1.6c.22 0 .4.18.4.4v2.8h2.8c.22 0 .4.18.4.4 0 .22-.18.4-.4.4H6.4v2.8c0 .22-.18.4-.4.4-.22 0-.4-.18-.4-.4V6.4H2.8c-.22 0-.4-.18-.4-.4 0-.22.18-.4.4-.4h2.8V2.8c0-.22.18-.4.4-.4z"></path>
                                </g>
                            </svg>

                            Add New Applicant
                        </a>
                    </div>
                </div>
                <div className="content-block registration-form -applicant">
                    <form className="new_applicant" id="new_applicant">
                        {learnerData.filter((search) => search.firstName === learner.split(" ")[0]).map((item) => (
                            <div className="content-block registration-form">
                                <div className="show-profile applicant-details-info">
                                    <div className="bar bar-default -no-top-border">
                                        <div className="person-block form-group">
                                            <img className="avatar -md" src={imgPerson} alt=""/>
                                            <div className="description">
                                                <div className="name">{`${item.firstName} ${item.surname}`}</div>
                                                <div className="role">Registered on {new Date(item.dateAdded).toLocaleDateString()}</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">First Name:</div>
                                                <div className="info-text">{item.firstName}</div>
                                            </div>
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">Middle Name(s):</div>
                                                <div className="info-text">{item.middleName === "" ? "N/A" : item.middleName}</div>
                                            </div>
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">Last Name:</div>
                                                <div className="info-text">{item.surname}</div>
                                            </div>
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">Gender:</div>
                                                <div className="info-text">{item.gender}</div>
                                            </div>
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">Date of Birth:</div>
                                                <div className="info-text">{item.birthday}</div>
                                            </div>
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">Race:</div>
                                                <div className="info-text">{item.race}</div>
                                            </div>
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">Enrollment Year:</div>
                                                <div className="info-text">{item.enrollmentYear} Academic Year</div>
                                            </div>
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">Anticipated Grade:</div>
                                                <div className="info-text">Grade {item.gradeEntry}</div>
                                            </div>
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">ID Type:</div>
                                                <div className="info-text">{item.idType}</div>
                                            </div>
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">ID/Passport Number:</div>
                                                <div className="info-text">{item.idNumber}</div>
                                            </div>
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">Nationality:</div>
                                                <div className="info-text">{item.nationality}</div>
                                            </div>
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">Current Grade:</div>
                                                <div className="info-text">Grade {item.currentGrade}</div>
                                            </div>
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">Name of Current School:</div>
                                                <div className="info-text">{item.currentSchool}</div>
                                            </div>
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">School Phone Number:</div>
                                                <div className="info-text">{item.schoolPhone === "" ? "N/A" : item.schoolPhone}</div>
                                            </div>
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">Current School City or Town:</div>
                                                <div className="info-text">{item.schoolTown}</div>
                                            </div>
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">Current School Province:</div>
                                                <div className="info-text">{item.schoolProvince}</div>
                                            </div>
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">Does learner have disability?:</div>
                                                <div className="info-text">{item.disability}</div>
                                            </div>
                                            <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                <div className="info-label">Learner stays with?:</div>
                                                <div className="info-text">{item.staysWith}</div>
                                            </div>
                                        </div>
                                        <div className="bar bar-inset clearfix bar-offset -no-border">
                                            <div className="pull-right">
                                                <a href={`/applicants/edit/${item.learnerId}`} className="btn btn-default">
                                                    Edit Applicant Information
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </form>
                </div>
            </div>
        </main>
    )
}

export default StudentInformationPage