import React, {useState} from "react";
import Schools from "../components/Schools";
import Sort from "../components/Sort";
import Filter from "../components/Filter";
import {useFilterContext} from "../context/filterContext";
import SEO from "../seo/SEO";
import Pagination from "../components/Pagination";

function SchoolSearchPage() {
    const { filters: {province, town, curriculum, isBoarding, schoolPhase, category}, pageSize, allSchools } = useFilterContext()

    const [number, setNumber] = useState(1)

    let tempSchools = allSchools.filter(function (school) {
        // const filteredSchools = school.province === gradeSelection.province
        //     && school.physical_address.includes(gradeSelection.town)

        let filteredSchools = allSchools.filter((search) => search.type === "School (Private)")

        if (province !== 'all') {
            filteredSchools = school.province === province
        }

        if (town !== "") {
            filteredSchools = school.physical_address.toLowerCase().includes(town.toLowerCase())
        }

        if (curriculum !== 'all') {
            filteredSchools = school.curriculum.toLowerCase().includes(curriculum.toLowerCase())
        }

        if (isBoarding !== 'all') {
            filteredSchools = school.isBoarding === isBoarding
        }

        if (schoolPhase !== 'all') {
            filteredSchools = school.schoolPhase.toLowerCase() === schoolPhase.toLowerCase()
        }

        if (category !== 'all') {
            filteredSchools = school.category.toLowerCase() === category.toLowerCase()
        }

        return filteredSchools
    })

    const postPerPage = pageSize
    const pageNumber = []

    const lastPost = number * postPerPage
    const firstPost = lastPost - postPerPage
    const currentPost = tempSchools.slice(firstPost, lastPost)

    for (let i = 1; i <= Math.ceil(tempSchools.length / postPerPage); i++) {
        pageNumber.push(i)
    }

    return (
        <main className="-no-padding-top">
            <SEO title="All Schools Search | Easy Applications"
                 description="Easy Applications is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://apply.easyapplications.co.za" />
            <div className="content-wrapper" style={{ textAlign: "left" }}>
                <div className="schools-list columns clearfix">
                    <div className="left-content column">
                        <Sort />
                        <div className="content-block">
                            <div className="res-content schools-result-list">
                                <a className="btn btn-default mobile-filters" href="#">Filter</a>
                                {currentPost.map((item) => (
                                    <Schools item={item} />
                                ))}
                            </div>
                            <div className="bar bar-inset clearfix bar-bottom">
                                <div className="pagination-wrapper">
                                    <div className="pull-left page-info">
                                        Displaying schools <b>1&nbsp;-&nbsp;10</b> of <b>47</b> in total
                                    </div>
                                    <div className="pull-right page-length">
                                        <div className="select2-wrapper">
                                            <select name="per_page" id="per_page" className="form-control">
                                                <option value="10">10 / Page</option>
                                                <option value="25">25 / Page</option>
                                                <option value="50">50 / Page</option>
                                                <option value="100">100 / Page</option>
                                            </select>
                                        </div>
                                    </div>
                                    <Pagination currentPage={number}
                                                totalCount={tempSchools.length}
                                                pageSize={postPerPage}
                                                onPageChange={page => setNumber(page)} />
                                    {/*<nav className="paginate">*/}
                                    {/*    <ul className="pagination">*/}
                                    {/*        <li className="disabled">*/}
                                    {/*            <a aria-label="Previous" rel="prev" href="#"><span*/}
                                    {/*                aria-hidden="true">Previous</span>*/}
                                    {/*            </a>*/}
                                    {/*        </li>*/}
                                    {/*        {pageNumber.map((item) => {*/}
                                    {/*            if (item === number) {*/}
                                    {/*                return (*/}
                                    {/*                    <li className="active">*/}
                                    {/*                        <a href="#">{item}</a>*/}
                                    {/*                    </li>*/}
                                    {/*                )*/}
                                    {/*            } else {*/}
                                    {/*                return (*/}
                                    {/*                    <li>*/}
                                    {/*                        <a href="#">{item}</a>*/}
                                    {/*                    </li>*/}
                                    {/*                )*/}
                                    {/*            }*/}
                                    {/*        })}*/}
                                    {/*        <li>*/}
                                    {/*            <a aria-label="Previous" rel="next" href="#">*/}
                                    {/*                <span aria-hidden="true">Next</span>*/}
                                    {/*            </a>*/}
                                    {/*        </li>*/}
                                    {/*    </ul>*/}
                                    {/*</nav>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Filter />
                </div>
            </div>
        </main>
    )
}

export default SchoolSearchPage