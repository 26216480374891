import React, {useEffect, useState} from "react";
import {useUserContext} from "../context/userContext";
import {db} from "../firebase";
import {onValue, ref} from "firebase/database";

function Schools({ item }) {
    const [{user, learnerId}, dispatch] = useUserContext()

    const [mySchools, setMySchools] = useState([])

    useEffect(() => {
        onValue(ref(db, `easyApplications/mySchool/${user}/${learnerId}`), snapshot => {
            if (snapshot.exists()) {
                setMySchools([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((item) => {
                        setMySchools((oldArray) => [...oldArray, item])
                    })
                }
            } else {
                setMySchools([])
            }
        })
    }, [learnerId])

    const removeSchool = (accreditationNo) => {
        db.ref("easyApplications").child(`/mySchool/${user}/${learnerId}/${accreditationNo}`).remove()
            .then(() => {
                alert("School successfully removed!")
            }).catch((err) => {
                alert(err.message)
            })
    }

    const addSchool = (accreditationNo, schoolName, physicalAddress, schoolPhase) => {

        if (user) {
            db.ref('easyApplications').child(`mySchool/${user}/${learnerId}/${accreditationNo}`).set({
                schoolName: schoolName,
                physicalAddress: physicalAddress,
                schoolPhase: schoolPhase,
                accreditationNo: accreditationNo,
                schoolId: accreditationNo

            }, () => {
                alert("School successfully added to my schools!")

            }).catch((e) => {
                alert(e.message)

            })
        } else {
            alert("Sign in first")
        }
    }

    return (
        <div className="school-block">
            <div className="school-thumbnail box" style={{backgroundImage: `url(https://directory-ca-prod.s3.amazonaws.com/schools/1011/photos/2140/thumbnail_70398-1.jpg)`}}>
                <a className="school-slider btn btn-default" data-toggle="modal"
                   href="#slide-1011">
                    <svg xmlns="http://www.w3.org/2000/svg" width="3" height="3"
                         viewBox="0 0 12 12">
                        <path fill="#859BBB" fillRule="evenodd"
                              d="M2.625 12C1.175 12 0 10.825 0 9.375v-6.75C0 1.175 1.175 0 2.625 0h6.75C10.825 0 12 1.175 12 2.625v6.75C12 10.825 10.825 12 9.375 12zm1.687-5.952l-3.314 4.26c.324.563.931.942 1.627.942l5.733-.001-4.046-5.201zM9.375.75h-6.75C1.59.75.75 1.59.75 2.625v6.781l3.266-4.199c.134-.171.38-.19.538-.057l.055.057 4.699 6.042.067.001c.986 0 1.795-.761 1.87-1.728l.005-.147v-6.75C11.25 1.59 10.41.75 9.375.75zM8.25 2.25c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm0 .75c-.414 0-.75.336-.75.75s.336.75.75.75.75-.336.75-.75S8.664 3 8.25 3z"></path>
                    </svg>

                    <span className="count">3</span>
                </a>
            </div>
            <div className="school-info box" style={{ width: '100%'}}>
                <div className="bar title bar-inset clearfix -no-border-top -no-border-bottom">
                    <div className="title">
                        <a href={`/schools/${item.name}`}>{item.name}</a>
                        <span className={item.hasOwnProperty("status") ? "label label-warning -left-margin" : "hidden"}>Featured</span>
                    </div>
                    <div className="right-block add-parent-wrapper">
                        {(() => {
                            if (mySchools.length === 0) {
                                return (
                                    <button className="btn btn-default add-parent js-add-parent item add_fields"
                                            onClick={() => addSchool(item.accreditation_no, item.name, item.physical_address, item.schoolPhase)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                            <g fill="none" fillRule="evenodd">
                                                <path fill="#FFF" fillOpacity="0" d="M0 0H12V12H0z"></path>
                                                <path fill="#859BBB"
                                                      d="M6 0c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6C.001 2.687 2.687.001 6 0zm0 .8C3.128.8.8 3.128.8 6c0 2.872 2.328 5.2 5.2 5.2 2.872 0 5.2-2.328 5.2-5.2C11.2 3.128 8.872.8 6 .8zm0 1.6c.22 0 .4.18.4.4v2.8h2.8c.22 0 .4.18.4.4 0 .22-.18.4-.4.4H6.4v2.8c0 .22-.18.4-.4.4-.22 0-.4-.18-.4-.4V6.4H2.8c-.22 0-.4-.18-.4-.4 0-.22.18-.4.4-.4h2.8V2.8c0-.22.18-.4.4-.4z"></path>
                                            </g>
                                        </svg>

                                        Add School
                                    </button>
                                )
                            }
                        })()}
                        {mySchools.map((addedItem) => {
                            if (addedItem.accreditationNo === item.accreditation_no) {
                                return (
                                    <button className="btn btn-secondary btn-danger btn-trash" onClick={() => removeSchool(item.accreditation_no)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
                                             preserveAspectRatio="xMidYMid" width="12" height="12"
                                             viewBox="-1 0 31 35">
                                            <path fillRule="evenodd" className="trash-svg" fill="#a6bedc"
                                                  d="M25.500,11.500 L25.500,27.500 C25.500,29.709 23.709,31.501 21.500,31.501 L7.500,31.501 C5.291,31.501 3.500,29.709 3.500,27.500 L3.500,11.500 C2.395,11.500 1.500,10.605 1.500,9.500 L1.500,7.500 C1.500,6.395 2.395,5.500 3.500,5.500 L9.500,5.500 L9.500,3.500 C9.500,2.395 10.395,1.500 11.500,1.500 L17.500,1.500 C18.605,1.500 19.500,2.395 19.500,3.500 L19.500,5.500 L25.500,5.500 C26.605,5.500 27.500,6.395 27.500,7.500 L27.500,9.500 C27.500,10.605 26.605,11.500 25.500,11.500 ZM5.500,11.500 L5.500,27.500 C5.500,28.605 6.395,29.501 7.500,29.501 L21.500,29.501 C22.605,29.501 23.500,28.605 23.500,27.500 L23.500,11.500 C22.375,11.500 6.875,11.500 5.500,11.500 ZM11.500,5.500 L11.500,4.500 C11.500,3.948 11.948,3.500 12.500,3.500 L16.500,3.500 C17.052,3.500 17.500,3.948 17.500,4.500 L17.500,5.500 C16.531,5.500 11.500,5.500 11.500,5.500 M24.500,7.500 L4.500,7.500 C3.948,7.500 3.500,7.948 3.500,8.500 C3.500,9.053 3.948,9.500 4.500,9.500 L24.500,9.500 C25.052,9.500 25.500,9.053 25.500,8.500 C25.500,7.948 25.052,7.500 24.500,7.500 ZM9.500,14.500 C10.052,14.500 10.500,14.948 10.500,15.500 L10.500,25.500 C10.500,26.053 10.052,26.500 9.500,26.500 C8.948,26.500 8.500,26.053 8.500,25.500 L8.500,15.500 C8.500,14.948 8.948,14.500 9.500,14.500 ZM14.500,14.500 C15.052,14.500 15.500,14.948 15.500,15.500 L15.500,25.500 C15.500,26.053 15.052,26.500 14.500,26.500 C13.948,26.500 13.500,26.053 13.500,25.500 L13.500,15.500 C13.500,14.948 13.948,14.500 14.500,14.500 ZM19.500,14.500 C20.052,14.500 20.500,14.948 20.500,15.500 L20.500,25.500 C20.500,26.053 20.052,26.500 19.500,26.500 C18.948,26.500 18.500,26.053 18.500,25.500 L18.500,15.500 C18.500,14.948 18.948,14.500 19.500,14.500 Z"></path>
                                        </svg>

                                        Remove School
                                    </button>
                                )
                            } else {
                                return (
                                    <button className="btn btn-default add-parent js-add-parent item add_fields"
                                            onClick={() => addSchool(item.accreditation_no, item.name, item.physical_address, item.schoolPhase)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                            <g fill="none" fillRule="evenodd">
                                                <path fill="#FFF" fillOpacity="0" d="M0 0H12V12H0z"></path>
                                                <path fill="#859BBB"
                                                      d="M6 0c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6C.001 2.687 2.687.001 6 0zm0 .8C3.128.8.8 3.128.8 6c0 2.872 2.328 5.2 5.2 5.2 2.872 0 5.2-2.328 5.2-5.2C11.2 3.128 8.872.8 6 .8zm0 1.6c.22 0 .4.18.4.4v2.8h2.8c.22 0 .4.18.4.4 0 .22-.18.4-.4.4H6.4v2.8c0 .22-.18.4-.4.4-.22 0-.4-.18-.4-.4V6.4H2.8c-.22 0-.4-.18-.4-.4 0-.22.18-.4.4-.4h2.8V2.8c0-.22.18-.4.4-.4z"></path>
                                            </g>
                                        </svg>

                                        Add School
                                    </button>
                                )
                            }
                        })}
                    </div>
                </div>
                <div className="location">
                    {item.physical_address}
                    <span className="country-flag flag iti-flag za"></span>
                </div>
                <div className="short-information">
                    <div className="info-box">
                        <div className="form-group">
                            <label>Curricula</label>
                            <div className="text">
                                {item.hasOwnProperty('curriculum') ? item.curriculum : "No information"}
                            </div>
                        </div>
                    </div>
                    <div className="info-box">
                        <div className="form-group">
                            <label>School Phase</label>
                            <div className="text">
                                {item.hasOwnProperty('schoolPhase') ? item.schoolPhase : 'No information'}
                            </div>
                        </div>
                    </div>
                    <div className="info-box">
                        <div className="form-group">
                            <label>Boarding/Day</label>
                            <div className="text">
                                {(() => {
                                    if (item.hasOwnProperty('isBoarding')) {
                                        if (item.isBoarding === 'No') {
                                            return (
                                                <span>
                                                    Day
                                                </span>
                                            )
                                        } else {
                                            return (
                                                <span>
                                                    Day <br/>Boarding
                                                </span>
                                            )
                                        }
                                    } else {
                                        return (
                                            "No information"
                                        )
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                    <div className="info-box">
                        <div className="form-group">
                            <label>Tuition (ZAR)</label>
                            <div className="text">
                                {(() => {
                                    if (item.hasOwnProperty('schoolFees')) {
                                        return (
                                            `R${item.schoolFees[0].fee} ~ R${item.schoolFees[item.schoolFees.length-1].fee}`
                                        )
                                    } else {
                                        return (
                                            "No information"
                                        )
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                    <div className="info-box">
                        <div className="form-group">
                            <label>Grades</label>
                            <div className="text">
                                {(() => {
                                    if (item.hasOwnProperty('lowestGrade')) {
                                        return (
                                            `Grd ${item.lowestGrade} ~ Grd ${item.highestGrade}`
                                        )
                                    } else {
                                        return (
                                            "No information"
                                        )
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                    <div className="info-box">
                        <div className="form-group">
                            <label>Type</label>
                            <div className="text">
                                {item.hasOwnProperty('category') ? item.category : 'No information'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Schools