import React, {useEffect, useState} from "react";
import {onValue, ref} from "firebase/database";
import {db, storage} from "../firebase";
import {useUserContext} from "../context/userContext";
import {school} from "../data/schoolsData";
import Requirements from "../components/Requirements";
import {getDownloadURL, ref as stRef, uploadBytesResumable} from "firebase/storage";
import SubmitApplication from "../components/SubmitApplication";
import SEO from "../seo/SEO";

function MySchoolPage() {
    const [{user, requirementStatus, learnerId}, dispatch] = useUserContext()

    const [mySchools, setMySchools] = useState([])

    useEffect(() => {
        onValue(ref(db, `easyApplications/mySchool/${user}/${learnerId}`), snapshot => {
            if (snapshot.exists()) {
                setMySchools([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((item) => {
                        setMySchools((oldArray) => [...oldArray, item])
                    })
                    console.log(mySchools)
                }
            } else {
                setMySchools([])
            }
        })
    }, [learnerId])

    return (
        <main className="-no-padding-top">
            <SEO title="My Schools | Easy Applications"
                 description="Easy Applications is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://apply.easyapplications.co.za" />
            <div className="content-wrapper" style={{ textAlign: "left" }}>
                <div id="flash-area"></div>
                <div className="container-fluid school-profile-main">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="columns">
                                {mySchools.map((item) => (
                                    <div className="left-content column">
                                        <div className="content-block school-profile">
                                            <SubmitApplication schoolName={item.schoolName} />
                                            <div className="res-content">
                                                <h3>Learner and Family Information</h3>
                                                <div className="school-infos school-data-info-block">
                                                    <ul className="school-infos-table info-list-style">
                                                        <li>
                                                            <i className="info-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                     height="12" viewBox="0 0 12 12">
                                                                    <g fill="none" fillRule="evenodd">
                                                                        <path fill="#FFF" fillOpacity="0"
                                                                              d="M0 0H12V12H0z"></path>
                                                                        <path fill="#859BBB"
                                                                              d="M2.64 0c-.43-.008-.846.156-1.155.457-.308.3-.483.713-.485 1.143v8.8c-.007.426.159.838.46 1.14.302.301.714.467 1.14.46h8.8V0H2.64zm7.96 11.2h-8c-.442 0-.8-.358-.8-.8 0-.442.358-.8.8-.8h8v1.6zm0-2.4h-8c-.28.105-.548.239-.8.4V1.6c.095-.396.404-.705.8-.8h8v8z"></path>
                                                                    </g>
                                                                </svg>

                                                            </i>
                                                            <span className="attr">Learner(s) information</span>
                                                            <span className="val">Yes</span>
                                                        </li>
                                                    </ul>
                                                    <ul className="school-infos-table info-list-style">
                                                        <li>
                                                            <i className="info-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                     height="12" viewBox="0 0 12 12">
                                                                    <g fill="none" fillRule="evenodd">
                                                                        <path fill="#FFF" fillOpacity="0"
                                                                              d="M0 0H12V12H0z"></path>
                                                                        <path fill="#859BBB"
                                                                              d="M10.002 9H7.77c-.407 0-.6-.186-.6-.6v-.6h-3.6v3.6c0 .414-.44.6-.864.6h-.768c-.424 0-.768-.186-.768-.6V.75c0-.414.344-.75.768-.75h.768c.424 0 .864.186.864.6v.6h3.3c.424 0 .9.186.9.6v.9h2.1c.424 0 .9.26.9.675V8.25c0 .414-.344.75-.768.75zM2.97.9c0-.207-.388-.3-.6-.3-.212 0-.6.093-.6.3v10.2c0 .207.388.3.6.3.212 0 .6-.093.6-.3V.9zm.6.9h3c.212 0 .6.093.6.3v4.8c0 .207-.388.3-.6.3h-3V1.8zm4.2 6.6h1.8c.212 0 .6-.093.6-.3V3.6c0-.207-.388-.3-.6-.3h-1.8v5.1z"></path>
                                                                    </g>
                                                                </svg>

                                                            </i>
                                                            <span className="attr">Family information</span>
                                                            <span className="val">Yes</span>
                                                        </li>
                                                    </ul>
                                                    <ul className="school-infos-table info-list-style">
                                                        <li>
                                                            <i className="info-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                     height="12" viewBox="0 0 12 12">
                                                                    <g fill="none" fillRule="evenodd">
                                                                        <path fill="#FFF" fillOpacity="0"
                                                                              d="M0 0H12V12H0z"></path>
                                                                        <path fill="#859BBB"
                                                                              d="M10.002 9H7.77c-.407 0-.6-.186-.6-.6v-.6h-3.6v3.6c0 .414-.44.6-.864.6h-.768c-.424 0-.768-.186-.768-.6V.75c0-.414.344-.75.768-.75h.768c.424 0 .864.186.864.6v.6h3.3c.424 0 .9.186.9.6v.9h2.1c.424 0 .9.26.9.675V8.25c0 .414-.344.75-.768.75zM2.97.9c0-.207-.388-.3-.6-.3-.212 0-.6.093-.6.3v10.2c0 .207.388.3.6.3.212 0 .6-.093.6-.3V.9zm.6.9h3c.212 0 .6.093.6.3v4.8c0 .207-.388.3-.6.3h-3V1.8zm4.2 6.6h1.8c.212 0 .6-.093.6-.3V3.6c0-.207-.388-.3-.6-.3h-1.8v5.1z"></path>
                                                                    </g>
                                                                </svg>

                                                            </i>
                                                            <span className="attr">All requirements document</span>
                                                            <span className="val">{requirementStatus}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <hr className="res-content-separate hidden-xs" />
                                                <h3>Requirements</h3>
                                                {school.filter((search) => search.accreditation_no === item.accreditationNo)[0]['requirements'].map((requiredItem) => (
                                                    <Requirements item={requiredItem} schoolName={item.schoolName} />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default MySchoolPage