import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {db} from "../firebase";
import {useUserContext} from "../context/userContext";
import SEO from "../seo/SEO";

function SendEnquiryPage() {
    const {schoolName} = useParams()
    const [{user}, dispatch] = useUserContext()

    const [enquiry, setEnquiry] = useState({
        message: "",
    })

    const handleChange = name => event => {
        const value = event.target.value
        setEnquiry({...enquiry, [name]: value })
    }

    const sendEnquiry = (e) => {
        e.preventDefault()

        const postId = db.ref().push().key

        db.ref('easyApplications').child(`/enquiry/${user}/${postId}`).update({
            schoolName: schoolName,
            message: enquiry.message,
            postId: postId,
            dateSent: new Date().getTime(),
            senderId: user,
            status: "Pending"

        }).then(() => {
            alert("Enquiry successfully sent!")
            sendEnquiry({ message: " " })

        }).catch((err) => {
            alert(err.message)

        })
    }

    return (
        <main>
            <SEO title="Send Enquiry | Easy Applications"
                 description="Easy Applications is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://apply.easyapplications.co.za" />
            <div className="content-wrapper" style={{ textAlign: "left" }}>
                <div id="flash-area"></div>
                <div className="content-block registration-form">
                    <div className="bar title bar-inset clearfix -bottom-margin">
                        <a className="btn-back or-line" href="/family/dashboard">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19">
                                <g fill="none" fillRule="evenodd">
                                    <path fill="#FFF" fillOpacity="0" d="M0 0H18V18H0z"></path>
                                    <path fill="#5A6A8F"
                                          d="M17.1 9.6H3.078l4.761 4.761c.353.353.353.925 0 1.278s-.925.353-1.278 0l-6.29-6.3C.096 9.171 0 8.941 0 8.7c0-.24.097-.471.27-.639l6.291-6.3c.172-.165.4-.258.639-.261.239 0 .468.095.639.261.351.354.351.924 0 1.278L3.079 7.8H17.1c.497 0 .9.403.9.9s-.403.9-.9.9z"></path>
                                </g>
                            </svg>

                        </a>
                        <div className="title">Send New Enquiry</div>
                    </div>
                    <form className="new_parent" id="new_parent" onSubmit={sendEnquiry}>
                        <div className="res-content parent-form">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="required" htmlFor="parent_first_name">Send To:</label>
                                        <span className="form-control">{schoolName}</span>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="required" htmlFor="parent_last_name">Enquiry</label>
                                        <textarea className="form-control" name="message" cols="30" rows="10" required
                                                  onChange={handleChange("message")}>

                                        </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bar bar-inset clearfix">
                            <div className="pull-right">
                                <input type="submit" name="commit" value="Send Enquiry" className="btn btn-primary"/>
                                <span className="or">or</span>
                                <a className="cancel-link" href="/family/dashboard">Cancel</a>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </main>
    )
}

export default SendEnquiryPage