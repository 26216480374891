import React, {useEffect, useState} from "react";
import {auth, db} from "../firebase";
import {useNavigate} from "react-router-dom";
import {profileLinks} from "../utils/constants";
import {useUserContext} from "../context/userContext";
import {onValue, ref} from "firebase/database";
import imgPerson from "../img/ic-person.png"
import SEO from "../seo/SEO";
import "../css/css.css"

function ProfilePage() {
    const [{user}, dispatch] = useUserContext()
    const history = useNavigate()

    const [selectedTab, setSelectedTab] = useState(0)
    const [firstName, setFirstName] = useState("")
    const [surname, setSurname] = useState("")
    const [dateJoined, setDateJoined] = useState("")
    const [email, setEmail] = useState("")
    const [myLearners, setMyLearners] = useState([])
    const [relationship, setRelationship] = useState("")
    const [parentsInfo, setParentsInfo] = useState([])


    useEffect(() => {
        onValue(ref(db, `easyApplications/accounts/${user}`), snapshot => {
            if (snapshot.exists()) {
                const firstName = snapshot.child("firstName").val()
                const surname = snapshot.child("surname").val()
                const dateJoined = snapshot.child("dateJoined").val()
                const email = snapshot.child("email").val()
                const relationship = snapshot.child("relationship").val()

                setFirstName(firstName)
                setSurname(surname)
                setDateJoined(dateJoined)
                setEmail(email)
                setRelationship(relationship)

            }
        })
        onValue(ref(db, `easyApplications/myLearners/${user}`), snapshot => {
            if (snapshot.exists()) {
                setMyLearners([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((item) => {
                        setMyLearners((oldArray) => [...oldArray, item])
                    })
                }
            }
        })
        onValue(ref(db, `easyApplications/profile/${user}/parentsInfo`), snapshot => {
            if (snapshot.exists()) {
                setParentsInfo([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((item) => {
                        setParentsInfo((oldArray) => [...oldArray, item])
                    })
                }
            }
        })
    }, [user])

    const logOut = () => {
        auth.signOut().then(() => {
            history('/')
        }).catch((err) => {
            alert(err.message)
        })
    }

    return (
        <main>
            <SEO title="Profile Information | Easy Applications"
                 description="Easy Applications is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://apply.easyapplications.co.za" />
            <div className="content-wrapper" style={{ textAlign: "left", marginTop: 14 }}>
                <div className="content-block user-dashboard -family">
                    <div className="bar title bar-inset clearfix">
                        <div className="title">
                            Profile
                        </div>
                        <div className="right-block">
                            <a className="btn btn-primary item user-edit" href={`/profile/edit/${user}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="EDIT_2_" viewBox="0 0 31 31" space="preserve" width="12" height="12">
                                    <path fillRule="evenodd" fill="#AACCFF"
                                          d="M30,30H0v-2h30V30z M23,8l-4-4l3-3c0.6-0.6,1.9-1.2,2.5-0.6l2.1,2.1c0.6,0.6,0,1.9-0.6,2.5L23,8z M10,21l-4-4  L18,5l4,4L10,21z M3,24l2-6l4,4L3,24z">

                                    </path>
                                </svg>

                                Edit Profile
                            </a>
                        </div>
                    </div>
                    <div className="res-content bottom-bar">
                        <div className="profile-logo">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="person-block -user">
                                        <div className="avatar-block">
                                            <img className="avatar -lm" src={imgPerson}  alt=""/>
                                            <div className="description">
                                                <div className="name">
                                                    {`${firstName} ${surname}`}
                                                </div>
                                                <div className="role">
                                                    Registered on {`${new Date().getDate()}/${new Date().getMonth()+1}/${new Date().getFullYear()}`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="profile-dropdown">
                                    {/*<div className="dropdown-title">*/}
                                    {/*    Go to*/}
                                    {/*</div>*/}
                                    <div className="dropdown" style={{ marginBottom: 20 }}>
                                        {/*<div className="dropdown">*/}
                                        {/*    <select name="" id="" className="form-control">*/}
                                        {/*        <option value="personal-information">Person Information</option>*/}
                                        {/*        <option value="family-information">Family Information</option>*/}
                                        {/*    </select>*/}
                                        {/*</div>*/}
                                        <ul className="nav nav-tabs dropdown-menu" id="profile-tab">
                                            {profileLinks.map((item, key) => {
                                                if (selectedTab === key) {
                                                    return (
                                                        <li className="active">
                                                            <a data-toggle="tab" href="#">
                                                                {item.title}
                                                            </a>
                                                        </li>
                                                    )
                                                } else {
                                                    return (
                                                        <li>
                                                            <a data-toggle="tab" href="#" onClick={() => setSelectedTab(key)}>
                                                                {item.title}
                                                            </a>
                                                        </li>
                                                    )
                                                }
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content">
                        {(() => {
                            switch (selectedTab) {
                                case 0:
                                    return (
                                        <div className="fade active in" id="personal-information">
                                            <div className="res-content -no-padding-top">
                                                <div className="row">
                                                    <div className="col-sm-4 col-xs-6">
                                                        <div className="form-group static">
                                                            <label>
                                                                First Name
                                                            </label>
                                                            <div className="text">
                                                                {firstName}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-xs-6">
                                                        <div className="form-group static">
                                                            <label>
                                                                Last Name
                                                            </label>
                                                            <div className="text">
                                                                {surname}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-xs-6">
                                                        <div className="form-group static">
                                                            <label>
                                                                Personal Email
                                                            </label>
                                                            <div className="text">
                                                                {email}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bar bar-default clearfix bar-subtitle children-title">
                                                <div className="title">
                                                    Children
                                                </div>
                                                <div className="right-block">
                                                    <a className="btn btn-default first item add-new-parent-button" href="/parents/new">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                             viewBox="0 0 12 12">
                                                            <g fill="none" fillRule="evenodd">
                                                                <path fill="#FFF" fillOpacity="0" d="M0 0H12V12H0z"></path>
                                                                <path fill="#859BBB"
                                                                      d="M6 0c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6C.001 2.687 2.687.001 6 0zm0 .8C3.128.8.8 3.128.8 6c0 2.872 2.328 5.2 5.2 5.2 2.872 0 5.2-2.328 5.2-5.2C11.2 3.128 8.872.8 6 .8zm0 1.6c.22 0 .4.18.4.4v2.8h2.8c.22 0 .4.18.4.4 0 .22-.18.4-.4.4H6.4v2.8c0 .22-.18.4-.4.4-.22 0-.4-.18-.4-.4V6.4H2.8c-.22 0-.4-.18-.4-.4 0-.22.18-.4.4-.4h2.8V2.8c0-.22.18-.4.4-.4z"></path>
                                                            </g>
                                                        </svg>

                                                        Add New Parent
                                                    </a>
                                                    <a className="btn btn-secondary item add-new-applicant-button" href="/applicants/new">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                                            <g fill="none" fillRule="evenodd">
                                                                <path fill="#FFF" fillOpacity="0" d="M0 0H12V12H0z"></path>
                                                                <path fill="#859BBB"
                                                                      d="M6 0c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6C.001 2.687 2.687.001 6 0zm0 .8C3.128.8.8 3.128.8 6c0 2.872 2.328 5.2 5.2 5.2 2.872 0 5.2-2.328 5.2-5.2C11.2 3.128 8.872.8 6 .8zm0 1.6c.22 0 .4.18.4.4v2.8h2.8c.22 0 .4.18.4.4 0 .22-.18.4-.4.4H6.4v2.8c0 .22-.18.4-.4.4-.22 0-.4-.18-.4-.4V6.4H2.8c-.22 0-.4-.18-.4-.4 0-.22.18-.4.4-.4h2.8V2.8c0-.22.18-.4.4-.4z"></path>
                                                            </g>
                                                        </svg>

                                                        Add Child
                                                    </a>
                                                </div>
                                            </div>
                                            <section id="children-list">
                                                {myLearners.map((item) => (
                                                    <div className="bar title bar-inset clearfix" id="applicant-164">
                                                        <div className="person-block">
                                                            <div className="avatar-block">
                                                                <img className="avatar -ds" src={imgPerson}  alt=""/>
                                                                <div className="description">
                                                                    <div className="name">
                                                                        <a className="name" href="/applicants/164">
                                                                            {`${item.firstName} ${item.surname}`}
                                                                        </a>
                                                                    </div>
                                                                    <div className="role">
                                                                        Enquiries sent to 2 Schools
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="right-block">
                                                            <a className="btn btn-default item btn-notext edit-children" href={`/applicants/edit/${item.learnerId}`}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="EDIT_2_" viewBox="0 0 31 31" space="preserve" width="12" height="12">
                                                                    <path fillRule="evenodd" fill="#AACCFF"
                                                                          d="M30,30H0v-2h30V30z M23,8l-4-4l3-3c0.6-0.6,1.9-1.2,2.5-0.6l2.1,2.1c0.6,0.6,0,1.9-0.6,2.5L23,8z M10,21l-4-4  L18,5l4,4L10,21z M3,24l2-6l4,4L3,24z"></path>
                                                                </svg>

                                                            </a>
                                                            <a className="btn btn-trash item btn-notext delete-children" data-confirm="Are you sure to delete?" data-name="Lloyd Biox"
                                                               data-remote="true" rel="nofollow" data-method="delete" href="/applicants/164">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid"
                                                                     width="12" height="12" viewBox="-1 0 31 35">
                                                                    <path fillRule="evenodd" className="trash-svg" fill="#a6bedc"
                                                                          d="M25.500,11.500 L25.500,27.500 C25.500,29.709 23.709,31.501 21.500,31.501 L7.500,31.501 C5.291,31.501 3.500,29.709 3.500,27.500 L3.500,11.500 C2.395,11.500 1.500,10.605 1.500,9.500 L1.500,7.500 C1.500,6.395 2.395,5.500 3.500,5.500 L9.500,5.500 L9.500,3.500 C9.500,2.395 10.395,1.500 11.500,1.500 L17.500,1.500 C18.605,1.500 19.500,2.395 19.500,3.500 L19.500,5.500 L25.500,5.500 C26.605,5.500 27.500,6.395 27.500,7.500 L27.500,9.500 C27.500,10.605 26.605,11.500 25.500,11.500 ZM5.500,11.500 L5.500,27.500 C5.500,28.605 6.395,29.501 7.500,29.501 L21.500,29.501 C22.605,29.501 23.500,28.605 23.500,27.500 L23.500,11.500 C22.375,11.500 6.875,11.500 5.500,11.500 ZM11.500,5.500 L11.500,4.500 C11.500,3.948 11.948,3.500 12.500,3.500 L16.500,3.500 C17.052,3.500 17.500,3.948 17.500,4.500 L17.500,5.500 C16.531,5.500 11.500,5.500 11.500,5.500 M24.500,7.500 L4.500,7.500 C3.948,7.500 3.500,7.948 3.500,8.500 C3.500,9.053 3.948,9.500 4.500,9.500 L24.500,9.500 C25.052,9.500 25.500,9.053 25.500,8.500 C25.500,7.948 25.052,7.500 24.500,7.500 ZM9.500,14.500 C10.052,14.500 10.500,14.948 10.500,15.500 L10.500,25.500 C10.500,26.053 10.052,26.500 9.500,26.500 C8.948,26.500 8.500,26.053 8.500,25.500 L8.500,15.500 C8.500,14.948 8.948,14.500 9.500,14.500 ZM14.500,14.500 C15.052,14.500 15.500,14.948 15.500,15.500 L15.500,25.500 C15.500,26.053 15.052,26.500 14.500,26.500 C13.948,26.500 13.500,26.053 13.500,25.500 L13.500,15.500 C13.500,14.948 13.948,14.500 14.500,14.500 ZM19.500,14.500 C20.052,14.500 20.500,14.948 20.500,15.500 L20.500,25.500 C20.500,26.053 20.052,26.500 19.500,26.500 C18.948,26.500 18.500,26.053 18.500,25.500 L18.500,15.500 C18.500,14.948 18.948,14.500 19.500,14.500 Z"></path>
                                                                </svg>

                                                            </a>
                                                        </div>
                                                    </div>
                                                ))}
                                            </section>
                                        </div>
                                    )
                                case 1:
                                    return (
                                        <div className="fade active in" id="family-information">
                                            <div className="res-content -no-padding-top">
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <div className="form-group static">
                                                            <label>
                                                                Relationship to Applicant
                                                            </label>
                                                            <div className="text">
                                                                {relationship}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bar bar-default clearfix bar-subtitle">
                                                <div className="title">Parents &amp; Guardians</div>
                                                <div className="right-block">
                                                    <a className="btn btn-default add-new-parent-button" href="/parents/new">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                                            <g fill="none" fillRule="evenodd">
                                                                <path fill="#FFF" fillOpacity="0" d="M0 0H12V12H0z"></path>
                                                                <path fill="#859BBB"
                                                                      d="M6 0c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6C.001 2.687 2.687.001 6 0zm0 .8C3.128.8.8 3.128.8 6c0 2.872 2.328 5.2 5.2 5.2 2.872 0 5.2-2.328 5.2-5.2C11.2 3.128 8.872.8 6 .8zm0 1.6c.22 0 .4.18.4.4v2.8h2.8c.22 0 .4.18.4.4 0 .22-.18.4-.4.4H6.4v2.8c0 .22-.18.4-.4.4-.22 0-.4-.18-.4-.4V6.4H2.8c-.22 0-.4-.18-.4-.4 0-.22.18-.4.4-.4h2.8V2.8c0-.22.18-.4.4-.4z"></path>
                                                            </g>
                                                        </svg>

                                                        Add New Parent
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="bar bar-default -no-top-border">
                                                {parentsInfo.filter((search) => search.relationship !== relationship).map((item) => (
                                                    <div className="parent-wrapper">
                                                        <div className="person-block -parent">
                                                            <img className="avatar -md" src={imgPerson} alt="" />
                                                            <div className="description">
                                                                <div className="name">
                                                                    <a href={`/parents/edit/${item.userId}`}>
                                                                        {`${item.firstName} ${item.surname}`}
                                                                    </a>
                                                                </div>
                                                                <div className="role">{item.relationship}</div>
                                                                <div className="role">{item.email}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )
                                default:
                                    return (
                                        <div className="tab-pane fade active in" id="personal-information">
                                            <div className="res-content -no-padding-top">
                                                <div className="row">
                                                    <div className="col-sm-4 col-xs-6">
                                                        <div className="form-group static">
                                                            <label>
                                                                First Name
                                                            </label>
                                                            <div className="text">
                                                                Lawrence
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-xs-6">
                                                        <div className="form-group static">
                                                            <label>
                                                                Last Name
                                                            </label>
                                                            <div className="text">
                                                                Biox
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-xs-6">
                                                        <div className="form-group static">
                                                            <label>
                                                                Personal Email
                                                            </label>
                                                            <div className="text">
                                                                lloydbiox@gmail.com
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bar bar-default clearfix bar-subtitle children-title">
                                                <div className="title">
                                                    Children
                                                </div>
                                                <div className="right-block">
                                                    <a className="btn btn-default first item add-new-parent-button" href="/parents/new">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                             viewBox="0 0 12 12">
                                                            <g fill="none" fillRule="evenodd">
                                                                <path fill="#FFF" fillOpacity="0" d="M0 0H12V12H0z"></path>
                                                                <path fill="#859BBB"
                                                                      d="M6 0c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6C.001 2.687 2.687.001 6 0zm0 .8C3.128.8.8 3.128.8 6c0 2.872 2.328 5.2 5.2 5.2 2.872 0 5.2-2.328 5.2-5.2C11.2 3.128 8.872.8 6 .8zm0 1.6c.22 0 .4.18.4.4v2.8h2.8c.22 0 .4.18.4.4 0 .22-.18.4-.4.4H6.4v2.8c0 .22-.18.4-.4.4-.22 0-.4-.18-.4-.4V6.4H2.8c-.22 0-.4-.18-.4-.4 0-.22.18-.4.4-.4h2.8V2.8c0-.22.18-.4.4-.4z"></path>
                                                            </g>
                                                        </svg>

                                                        Add New Parent
                                                    </a>
                                                    <a className="btn btn-secondary item add-new-applicant-button" href="/applicants/new">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                                            <g fill="none" fillRule="evenodd">
                                                                <path fill="#FFF" fillOpacity="0" d="M0 0H12V12H0z"></path>
                                                                <path fill="#859BBB"
                                                                      d="M6 0c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6C.001 2.687 2.687.001 6 0zm0 .8C3.128.8.8 3.128.8 6c0 2.872 2.328 5.2 5.2 5.2 2.872 0 5.2-2.328 5.2-5.2C11.2 3.128 8.872.8 6 .8zm0 1.6c.22 0 .4.18.4.4v2.8h2.8c.22 0 .4.18.4.4 0 .22-.18.4-.4.4H6.4v2.8c0 .22-.18.4-.4.4-.22 0-.4-.18-.4-.4V6.4H2.8c-.22 0-.4-.18-.4-.4 0-.22.18-.4.4-.4h2.8V2.8c0-.22.18-.4.4-.4z"></path>
                                                            </g>
                                                        </svg>

                                                        Add Child
                                                    </a>
                                                </div>
                                            </div>
                                            <section id="children-list">
                                                <div className="bar title bar-inset clearfix" id="applicant-164">
                                                    <div className="person-block">
                                                        <div className="avatar-block">
                                                            <img className="avatar -ds"
                                                                 src="https://search.openapply.com/assets/fallbacks/avatar-d92b62e325a1bb53492819a8e9fc60da7c46dd3390459ce4ac27d6eb57dd1773.png" />
                                                            <div className="description">
                                                                <div className="name">
                                                                    <a className="name" href="/applicants/164">Lloyd Biox</a>
                                                                </div>
                                                                <div className="role">
                                                                    Enquiries sent to 2 Schools
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="right-block">
                                                        <a className="btn btn-default item btn-notext edit-children"
                                                           data-name="Lloyd Biox" href="/applicants/164/edit">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="EDIT_2_" viewBox="0 0 31 31" space="preserve" width="12" height="12">
                                                                <path fill-rule="evenodd" fill="#AACCFF"
                                                                      d="M30,30H0v-2h30V30z M23,8l-4-4l3-3c0.6-0.6,1.9-1.2,2.5-0.6l2.1,2.1c0.6,0.6,0,1.9-0.6,2.5L23,8z M10,21l-4-4  L18,5l4,4L10,21z M3,24l2-6l4,4L3,24z"></path>
                                                            </svg>

                                                        </a>
                                                        <a className="btn btn-trash item btn-notext delete-children" data-confirm="Are you sure to delete?" data-name="Lloyd Biox"
                                                           data-remote="true" rel="nofollow" data-method="delete" href="/applicants/164">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid"
                                                                 width="12" height="12" viewBox="-1 0 31 35">
                                                                <path fill-rule="evenodd" className="trash-svg" fill="#a6bedc"
                                                                      d="M25.500,11.500 L25.500,27.500 C25.500,29.709 23.709,31.501 21.500,31.501 L7.500,31.501 C5.291,31.501 3.500,29.709 3.500,27.500 L3.500,11.500 C2.395,11.500 1.500,10.605 1.500,9.500 L1.500,7.500 C1.500,6.395 2.395,5.500 3.500,5.500 L9.500,5.500 L9.500,3.500 C9.500,2.395 10.395,1.500 11.500,1.500 L17.500,1.500 C18.605,1.500 19.500,2.395 19.500,3.500 L19.500,5.500 L25.500,5.500 C26.605,5.500 27.500,6.395 27.500,7.500 L27.500,9.500 C27.500,10.605 26.605,11.500 25.500,11.500 ZM5.500,11.500 L5.500,27.500 C5.500,28.605 6.395,29.501 7.500,29.501 L21.500,29.501 C22.605,29.501 23.500,28.605 23.500,27.500 L23.500,11.500 C22.375,11.500 6.875,11.500 5.500,11.500 ZM11.500,5.500 L11.500,4.500 C11.500,3.948 11.948,3.500 12.500,3.500 L16.500,3.500 C17.052,3.500 17.500,3.948 17.500,4.500 L17.500,5.500 C16.531,5.500 11.500,5.500 11.500,5.500 M24.500,7.500 L4.500,7.500 C3.948,7.500 3.500,7.948 3.500,8.500 C3.500,9.053 3.948,9.500 4.500,9.500 L24.500,9.500 C25.052,9.500 25.500,9.053 25.500,8.500 C25.500,7.948 25.052,7.500 24.500,7.500 ZM9.500,14.500 C10.052,14.500 10.500,14.948 10.500,15.500 L10.500,25.500 C10.500,26.053 10.052,26.500 9.500,26.500 C8.948,26.500 8.500,26.053 8.500,25.500 L8.500,15.500 C8.500,14.948 8.948,14.500 9.500,14.500 ZM14.500,14.500 C15.052,14.500 15.500,14.948 15.500,15.500 L15.500,25.500 C15.500,26.053 15.052,26.500 14.500,26.500 C13.948,26.500 13.500,26.053 13.500,25.500 L13.500,15.500 C13.500,14.948 13.948,14.500 14.500,14.500 ZM19.500,14.500 C20.052,14.500 20.500,14.948 20.500,15.500 L20.500,25.500 C20.500,26.053 20.052,26.500 19.500,26.500 C18.948,26.500 18.500,26.053 18.500,25.500 L18.500,15.500 C18.500,14.948 18.948,14.500 19.500,14.500 Z"></path>
                                                            </svg>

                                                        </a>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    )
                            }
                        })()}
{/*                        <div className="tab-pane fade" id="my-inquiries">*/}
{/*                            <div className="res-content -no-padding-top">*/}
{/*                                <div className="row">*/}
{/*                                    <div className="col-xs-6">*/}
{/*                                        <div className="form-group select2-wrapper children-select">*/}
{/*                                            <label>Children</label>*/}
{/*                                            <select className="select select2-box select2-hidden-accessible"*/}
{/*                                                    tabIndex="-1" aria-hidden="true">*/}
{/*                                                <option value="all">All</option>*/}
{/*                                                <option value="lloyd_biox">*/}
{/*                                                    Lloyd Biox*/}
{/*                                                </option>*/}
{/*                                            </select>*/}
{/*                                            <span className="select2 select2-container select2-container--default" dir="ltr">*/}
{/*                                                <span className="selection">*/}
{/*                                                    <span className="select2-selection select2-selection--single select2-box"*/}
{/*                                                        role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex="0"*/}
{/*                                                        aria-labelledby="select2-x3pl-container">*/}
{/*                                                        <span className="select2-selection__rendered" id="select2-x3pl-container" title="All">*/}
{/*                                                            All*/}
{/*                                                        </span>*/}
{/*                                                        <span className="select2-selection__arrow" role="presentation">*/}
{/*                                                            <b role="presentation"></b>*/}
{/*                                                        </span>*/}
{/*                                                    </span>*/}
{/*                                                </span>*/}
{/*                                                <span className="dropdown-wrapper" aria-hidden="true"></span>*/}
{/*                                            </span>*/}
{/*                                        </div>*/}
{/*                                    </div>*/}
{/*                                    <div className="col-xs-6">*/}
{/*                                        <div className="form-group clearfix">*/}
{/*                                            <label className="empty-label"></label>*/}
{/*                                            <a className="btn btn-primary pull-right js-confirm" href="#">Submit*/}
{/*                                                Enquiries</a>*/}
{/*                                        </div>*/}
{/*                                    </div>*/}
{/*                                </div>*/}
{/*                            </div>*/}
{/*                            <div className="table-responsive inquiries-table">*/}
{/*                                <form action="/family/submit_inquiries_schools" accept-charset="UTF-8" method="post">*/}
{/*                                    <input name="utf8" type="hidden" value="✓" />*/}
{/*                                    <input type="hidden" name="_method" value="put" />*/}
{/*                                    <input type="hidden" name="authenticity_token"*/}
{/*                                           value="mhpcx0w/8B+eQ5bQOuj3AtPflmlsy1BpfdcvG3zhTNmnLOYyMYYAMlJ0eOiv4iIDsreNUbPy56zjGOQoR/MbVA==" />*/}
{/*                                        <table className="-no-margin-bottom inquiries-list inquiries-list-all inquiry-list table table-striped" style={{display: "table"}}>*/}
{/*                                            <thead>*/}
{/*                                            <tr>*/}
{/*                                                <th className="checkbox-td">*/}
{/*                                                    <div className="form-group">*/}
{/*                                                        <div className="checkbox checkbox-single">*/}
{/*                                                            <label>*/}
{/*                                                                <input className="select-checkboxes" type="checkbox" />*/}
{/*                                                                <span></span>*/}
{/*                                                            </label>*/}
{/*                                                        </div>*/}
{/*                                                    </div>*/}
{/*                                                </th>*/}
{/*                                                <th>*/}
{/*                                                    School Name*/}
{/*                                                </th>*/}
{/*                                                <th>*/}
{/*                                                    Campus*/}
{/*                                                </th>*/}
{/*                                                <th>*/}
{/*                                                    City*/}
{/*                                                </th>*/}
{/*                                                <th>*/}
{/*                                                    Country*/}
{/*                                                </th>*/}
{/*                                                <th>*/}
{/*                                                    Child*/}
{/*                                                </th>*/}
{/*                                                <th>*/}
{/*                                                    Status*/}
{/*                                                </th>*/}
{/*                                            </tr>*/}
{/*                                            </thead>*/}
{/*                                            <tbody>*/}
{/*                                            <tr>*/}
{/*                                                <td className="checkbox-td">*/}
{/*                                                    <div className="form-group">*/}
{/*                                                        <div className="checkbox checkbox-single js-input-checkboxes">*/}
{/*                                                            <label>*/}
{/*                                                                <input data-school-name="A+ World Academy"*/}
{/*                                                                       name="inquiries_school_ids[]" type="checkbox"*/}
{/*                                                                       value="13" />*/}
{/*                                                                <span></span>*/}
{/*                                                            </label>*/}
{/*                                                        </div>*/}
{/*                                                    </div>*/}
{/*                                                </td>*/}
{/*                                                <td>*/}
{/*                                                    <a className="name" target="_blank" href="/schools/386">A+ World Academy</a>*/}
{/*                                                </td>*/}
{/*                                                <td>*/}
{/*                                                    A+ World Academy*/}
{/*                                                </td>*/}
{/*                                                <td>*/}
{/*                                                    Kristiansand*/}
{/*                                                </td>*/}
{/*                                                <td>*/}

{/*                                                </td>*/}
{/*                                                <td>*/}
{/*                                                    Lloyd Biox*/}
{/*                                                </td>*/}
{/*                                                <td className="status-td">*/}
{/*                                                    <div className="status-action">*/}
{/*                                                        <span className="badge-icon badge-pending">*/}
{/*                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">*/}
{/*                                                                <g fill="none" fill-rule="evenodd">*/}
{/*                                                                    <path fill="#FFF" fill-opacity="0" d="M0 0H12V12H0z"></path>*/}
{/*                                                                    <path fill="#F59D00"*/}
{/*                                                                          d="M6 12c-3.308 0-6-2.692-6-6s2.692-6 6-6 6 2.692 6 6-2.692 6-6 6zM6 .6C3.105.6.6 3.105.6 6c0 2.895 2.505 5.4 5.4 5.4 2.895 0 5.4-2.505 5.4-5.4C11.4 3.105 8.895.6 6 .6zm2.7 5.7H6c-.207 0-.3-.093-.3-.3V2.4c0-.207.093-.3.3-.3s.3.093.3.3v3.3h2.4c.207 0 .3.093.3.3s-.093.3-.3.3z"></path>*/}
{/*                                                                </g>*/}
{/*                                                            </svg>*/}

{/*                                                            Not Submitted*/}
{/*                                                        </span>*/}
{/*                                                        <a className="btn btn-trash btn-notext" rel="nofollow" data-method="delete"*/}
{/*                                                           href="/family/13/delete_inquiries_school">*/}
{/*                                                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" width="12" height="12"*/}
{/*                                                                 viewBox="-1 0 31 35">*/}
{/*                                                                <path fill-rule="evenodd" className="trash-svg"*/}
{/*                                                                      fill="#a6bedc"*/}
{/*                                                                      d="M25.500,11.500 L25.500,27.500 C25.500,29.709 23.709,31.501 21.500,31.501 L7.500,31.501 C5.291,31.501 3.500,29.709 3.500,27.500 L3.500,11.500 C2.395,11.500 1.500,10.605 1.500,9.500 L1.500,7.500 C1.500,6.395 2.395,5.500 3.500,5.500 L9.500,5.500 L9.500,3.500 C9.500,2.395 10.395,1.500 11.500,1.500 L17.500,1.500 C18.605,1.500 19.500,2.395 19.500,3.500 L19.500,5.500 L25.500,5.500 C26.605,5.500 27.500,6.395 27.500,7.500 L27.500,9.500 C27.500,10.605 26.605,11.500 25.500,11.500 ZM5.500,11.500 L5.500,27.500 C5.500,28.605 6.395,29.501 7.500,29.501 L21.500,29.501 C22.605,29.501 23.500,28.605 23.500,27.500 L23.500,11.500 C22.375,11.500 6.875,11.500 5.500,11.500 ZM11.500,5.500 L11.500,4.500 C11.500,3.948 11.948,3.500 12.500,3.500 L16.500,3.500 C17.052,3.500 17.500,3.948 17.500,4.500 L17.500,5.500 C16.531,5.500 11.500,5.500 11.500,5.500 M24.500,7.500 L4.500,7.500 C3.948,7.500 3.500,7.948 3.500,8.500 C3.500,9.053 3.948,9.500 4.500,9.500 L24.500,9.500 C25.052,9.500 25.500,9.053 25.500,8.500 C25.500,7.948 25.052,7.500 24.500,7.500 ZM9.500,14.500 C10.052,14.500 10.500,14.948 10.500,15.500 L10.500,25.500 C10.500,26.053 10.052,26.500 9.500,26.500 C8.948,26.500 8.500,26.053 8.500,25.500 L8.500,15.500 C8.500,14.948 8.948,14.500 9.500,14.500 ZM14.500,14.500 C15.052,14.500 15.500,14.948 15.500,15.500 L15.500,25.500 C15.500,26.053 15.052,26.500 14.500,26.500 C13.948,26.500 13.500,26.053 13.500,25.500 L13.500,15.500 C13.500,14.948 13.948,14.500 14.500,14.500 ZM19.500,14.500 C20.052,14.500 20.500,14.948 20.500,15.500 L20.500,25.500 C20.500,26.053 20.052,26.500 19.500,26.500 C18.948,26.500 18.500,26.053 18.500,25.500 L18.500,15.500 C18.500,14.948 18.948,14.500 19.500,14.500 Z"></path>*/}
{/*                                                            </svg>*/}
{/*                                                        </a>*/}
{/*                                                    </div>*/}
{/*                                                </td>*/}
{/*                                            </tr>*/}
{/*                                            <tr>*/}
{/*                                                <td className="checkbox-td">*/}
{/*                                                    <div className="form-group">*/}
{/*                                                        <div className="checkbox checkbox-single js-input-checkboxes">*/}
{/*                                                            <label>*/}
{/*                                                                <input data-school-name="IES College"*/}
{/*                                                                       name="inquiries_school_ids[]" type="checkbox"*/}
{/*                                                                       value="14" />*/}
{/*                                                                <span></span>*/}
{/*                                                            </label>*/}
{/*                                                        </div>*/}
{/*                                                    </div>*/}
{/*                                                </td>*/}
{/*                                                <td>*/}
{/*                                                    <a className="name" target="_blank" href="/schools/828">IES*/}
{/*                                                        College</a>*/}
{/*                                                </td>*/}
{/*                                                <td>*/}
{/*                                                    IES College*/}
{/*                                                </td>*/}
{/*                                                <td>*/}
{/*                                                    Brisbane*/}
{/*                                                </td>*/}
{/*                                                <td>*/}

{/*                                                </td>*/}
{/*                                                <td>*/}
{/*                                                    Lloyd Biox*/}
{/*                                                </td>*/}
{/*                                                <td className="status-td">*/}
{/*                                                    <div className="status-action">*/}
{/*                                                        <span className="badge-icon badge-pending">*/}
{/*                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">*/}
{/*                                                                <g fill="none" fill-rule="evenodd">*/}
{/*                                                                    <path fill="#FFF" fill-opacity="0" d="M0 0H12V12H0z"></path>*/}
{/*                                                                    <path fill="#F59D00"*/}
{/*                                                                          d="M6 12c-3.308 0-6-2.692-6-6s2.692-6 6-6 6 2.692 6 6-2.692 6-6 6zM6 .6C3.105.6.6 3.105.6 6c0 2.895 2.505 5.4 5.4 5.4 2.895 0 5.4-2.505 5.4-5.4C11.4 3.105 8.895.6 6 .6zm2.7 5.7H6c-.207 0-.3-.093-.3-.3V2.4c0-.207.093-.3.3-.3s.3.093.3.3v3.3h2.4c.207 0 .3.093.3.3s-.093.3-.3.3z"></path>*/}
{/*                                                                </g>*/}
{/*                                                            </svg>*/}

{/*                                                            Not Submitted*/}
{/*                                                        </span>*/}
{/*                                                        <a className="btn btn-trash btn-notext" rel="nofollow" data-method="delete"*/}
{/*                                                           href="/family/14/delete_inquiries_school">*/}
{/*                                                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"*/}
{/*                                                                 preserveAspectRatio="xMidYMid" width="12" height="12"*/}
{/*                                                                 viewBox="-1 0 31 35">*/}
{/*                                                                <path fill-rule="evenodd" className="trash-svg"*/}
{/*                                                                      fill="#a6bedc"*/}
{/*                                                                      d="M25.500,11.500 L25.500,27.500 C25.500,29.709 23.709,31.501 21.500,31.501 L7.500,31.501 C5.291,31.501 3.500,29.709 3.500,27.500 L3.500,11.500 C2.395,11.500 1.500,10.605 1.500,9.500 L1.500,7.500 C1.500,6.395 2.395,5.500 3.500,5.500 L9.500,5.500 L9.500,3.500 C9.500,2.395 10.395,1.500 11.500,1.500 L17.500,1.500 C18.605,1.500 19.500,2.395 19.500,3.500 L19.500,5.500 L25.500,5.500 C26.605,5.500 27.500,6.395 27.500,7.500 L27.500,9.500 C27.500,10.605 26.605,11.500 25.500,11.500 ZM5.500,11.500 L5.500,27.500 C5.500,28.605 6.395,29.501 7.500,29.501 L21.500,29.501 C22.605,29.501 23.500,28.605 23.500,27.500 L23.500,11.500 C22.375,11.500 6.875,11.500 5.500,11.500 ZM11.500,5.500 L11.500,4.500 C11.500,3.948 11.948,3.500 12.500,3.500 L16.500,3.500 C17.052,3.500 17.500,3.948 17.500,4.500 L17.500,5.500 C16.531,5.500 11.500,5.500 11.500,5.500 M24.500,7.500 L4.500,7.500 C3.948,7.500 3.500,7.948 3.500,8.500 C3.500,9.053 3.948,9.500 4.500,9.500 L24.500,9.500 C25.052,9.500 25.500,9.053 25.500,8.500 C25.500,7.948 25.052,7.500 24.500,7.500 ZM9.500,14.500 C10.052,14.500 10.500,14.948 10.500,15.500 L10.500,25.500 C10.500,26.053 10.052,26.500 9.500,26.500 C8.948,26.500 8.500,26.053 8.500,25.500 L8.500,15.500 C8.500,14.948 8.948,14.500 9.500,14.500 ZM14.500,14.500 C15.052,14.500 15.500,14.948 15.500,15.500 L15.500,25.500 C15.500,26.053 15.052,26.500 14.500,26.500 C13.948,26.500 13.500,26.053 13.500,25.500 L13.500,15.500 C13.500,14.948 13.948,14.500 14.500,14.500 ZM19.500,14.500 C20.052,14.500 20.500,14.948 20.500,15.500 L20.500,25.500 C20.500,26.053 20.052,26.500 19.500,26.500 C18.948,26.500 18.500,26.053 18.500,25.500 L18.500,15.500 C18.500,14.948 18.948,14.500 19.500,14.500 Z"></path>*/}
{/*                                                            </svg>*/}
{/*                                                        </a>*/}
{/*                                                    </div>*/}
{/*                                                </td>*/}
{/*                                            </tr>*/}
{/*                                            </tbody>*/}
{/*                                        </table>*/}
{/*                                        <table*/}
{/*                                            className="-no-margin-bottom inquiries-list inquiries-list-lloyd_biox inquiry-list table table-striped"*/}
{/*                                            style={{display: "none"}}>*/}
{/*                                            <thead>*/}
{/*                                            <tr>*/}
{/*                                                <th className="checkbox-td">*/}
{/*                                                    <div className="form-group">*/}
{/*                                                        <div className="checkbox checkbox-single">*/}
{/*                                                            <label>*/}
{/*                                                                <input className="select-checkboxes" type="checkbox" />*/}
{/*                                                                <span></span>*/}
{/*                                                            </label>*/}
{/*                                                        </div>*/}
{/*                                                    </div>*/}
{/*                                                </th>*/}
{/*                                                <th>*/}
{/*                                                    School Name*/}
{/*                                                </th>*/}
{/*                                                <th>*/}
{/*                                                    Campus*/}
{/*                                                </th>*/}
{/*                                                <th>*/}
{/*                                                    City*/}
{/*                                                </th>*/}
{/*                                                <th>*/}
{/*                                                    Country*/}
{/*                                                </th>*/}
{/*                                                <th>*/}
{/*                                                    Child*/}
{/*                                                </th>*/}
{/*                                                <th>*/}
{/*                                                    Status*/}
{/*                                                </th>*/}
{/*                                            </tr>*/}
{/*                                            </thead>*/}
{/*                                            <tbody>*/}
{/*                                            <tr>*/}
{/*                                                <td className="checkbox-td">*/}
{/*                                                    <div className="form-group">*/}
{/*                                                        <div className="checkbox checkbox-single js-input-checkboxes">*/}
{/*                                                            <label>*/}
{/*                                                                <input data-school-name="A+ World Academy"*/}
{/*                                                                       name="inquiries_school_ids[]" type="checkbox"*/}
{/*                                                                       value="13" />*/}
{/*                                                                    <span></span>*/}
{/*                                                            </label>*/}
{/*                                                        </div>*/}
{/*                                                    </div>*/}
{/*                                                </td>*/}
{/*                                                <td>*/}
{/*                                                    <a className="name" target="_blank" href="/schools/386">A+ World Academy</a>*/}
{/*                                                </td>*/}
{/*                                                <td>*/}
{/*                                                    A+ World Academy*/}
{/*                                                </td>*/}
{/*                                                <td>*/}
{/*                                                    Kristiansand*/}
{/*                                                </td>*/}
{/*                                                <td>*/}

{/*                                                </td>*/}
{/*                                                <td>*/}
{/*                                                    Lloyd Biox*/}
{/*                                                </td>*/}
{/*                                                <td className="status-td">*/}
{/*                                                    <div className="status-action">*/}
{/*                                                        <span className="badge-icon badge-pending">*/}
{/*                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">*/}
{/*                                                                <g fill="none" fill-rule="evenodd">*/}
{/*                                                                    <path fill="#FFF" fill-opacity="0" d="M0 0H12V12H0z"></path>*/}
{/*                                                                    <path fill="#F59D00"*/}
{/*                                                                          d="M6 12c-3.308 0-6-2.692-6-6s2.692-6 6-6 6 2.692 6 6-2.692 6-6 6zM6 .6C3.105.6.6 3.105.6 6c0 2.895 2.505 5.4 5.4 5.4 2.895 0 5.4-2.505 5.4-5.4C11.4 3.105 8.895.6 6 .6zm2.7 5.7H6c-.207 0-.3-.093-.3-.3V2.4c0-.207.093-.3.3-.3s.3.093.3.3v3.3h2.4c.207 0 .3.093.3.3s-.093.3-.3.3z"></path>*/}
{/*                                                                </g>*/}
{/*                                                            </svg>*/}

{/*                                                            Not Submitted*/}
{/*                                                        </span>*/}
{/*                                                        <a className="btn btn-trash btn-notext" rel="nofollow"*/}
{/*                                                           data-method="delete"*/}
{/*                                                           href="/family/13/delete_inquiries_school">*/}
{/*                                                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"*/}
{/*                                                                 preserveAspectRatio="xMidYMid" width="12" height="12"*/}
{/*                                                                 viewBox="-1 0 31 35">*/}
{/*                                                                <path fill-rule="evenodd" className="trash-svg"*/}
{/*                                                                      fill="#a6bedc"*/}
{/*                                                                      d="M25.500,11.500 L25.500,27.500 C25.500,29.709 23.709,31.501 21.500,31.501 L7.500,31.501 C5.291,31.501 3.500,29.709 3.500,27.500 L3.500,11.500 C2.395,11.500 1.500,10.605 1.500,9.500 L1.500,7.500 C1.500,6.395 2.395,5.500 3.500,5.500 L9.500,5.500 L9.500,3.500 C9.500,2.395 10.395,1.500 11.500,1.500 L17.500,1.500 C18.605,1.500 19.500,2.395 19.500,3.500 L19.500,5.500 L25.500,5.500 C26.605,5.500 27.500,6.395 27.500,7.500 L27.500,9.500 C27.500,10.605 26.605,11.500 25.500,11.500 ZM5.500,11.500 L5.500,27.500 C5.500,28.605 6.395,29.501 7.500,29.501 L21.500,29.501 C22.605,29.501 23.500,28.605 23.500,27.500 L23.500,11.500 C22.375,11.500 6.875,11.500 5.500,11.500 ZM11.500,5.500 L11.500,4.500 C11.500,3.948 11.948,3.500 12.500,3.500 L16.500,3.500 C17.052,3.500 17.500,3.948 17.500,4.500 L17.500,5.500 C16.531,5.500 11.500,5.500 11.500,5.500 M24.500,7.500 L4.500,7.500 C3.948,7.500 3.500,7.948 3.500,8.500 C3.500,9.053 3.948,9.500 4.500,9.500 L24.500,9.500 C25.052,9.500 25.500,9.053 25.500,8.500 C25.500,7.948 25.052,7.500 24.500,7.500 ZM9.500,14.500 C10.052,14.500 10.500,14.948 10.500,15.500 L10.500,25.500 C10.500,26.053 10.052,26.500 9.500,26.500 C8.948,26.500 8.500,26.053 8.500,25.500 L8.500,15.500 C8.500,14.948 8.948,14.500 9.500,14.500 ZM14.500,14.500 C15.052,14.500 15.500,14.948 15.500,15.500 L15.500,25.500 C15.500,26.053 15.052,26.500 14.500,26.500 C13.948,26.500 13.500,26.053 13.500,25.500 L13.500,15.500 C13.500,14.948 13.948,14.500 14.500,14.500 ZM19.500,14.500 C20.052,14.500 20.500,14.948 20.500,15.500 L20.500,25.500 C20.500,26.053 20.052,26.500 19.500,26.500 C18.948,26.500 18.500,26.053 18.500,25.500 L18.500,15.500 C18.500,14.948 18.948,14.500 19.500,14.500 Z"></path>*/}
{/*                                                            </svg>*/}

{/*                                                        </a></div>*/}
{/*                                                </td>*/}
{/*                                            </tr>*/}
{/*                                            <tr>*/}
{/*                                                <td className="checkbox-td">*/}
{/*                                                    <div className="form-group">*/}
{/*                                                        <div className="checkbox checkbox-single js-input-checkboxes">*/}
{/*                                                            <label>*/}
{/*                                                                <input data-school-name="IES College"*/}
{/*                                                                       name="inquiries_school_ids[]" type="checkbox"*/}
{/*                                                                       value="14" />*/}
{/*                                                                <span></span>*/}
{/*                                                            </label>*/}
{/*                                                        </div>*/}
{/*                                                    </div>*/}
{/*                                                </td>*/}
{/*                                                <td>*/}
{/*                                                    <a className="name" target="_blank" href="/schools/828">IES*/}
{/*                                                        College</a>*/}
{/*                                                </td>*/}
{/*                                                <td>*/}
{/*                                                    IES College*/}
{/*                                                </td>*/}
{/*                                                <td>*/}
{/*                                                    Brisbane*/}
{/*                                                </td>*/}
{/*                                                <td>*/}

{/*                                                </td>*/}
{/*                                                <td>*/}
{/*                                                    Lloyd Biox*/}
{/*                                                </td>*/}
{/*                                                <td className="status-td">*/}
{/*                                                    <div className="status-action">*/}
{/*                                                        <span className="badge-icon badge-pending">*/}
{/*                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">*/}
{/*                                                                <g fill="none" fill-rule="evenodd">*/}
{/*                                                                    <path fill="#FFF" fill-opacity="0" d="M0 0H12V12H0z"></path>*/}
{/*                                                                    <path fill="#F59D00"*/}
{/*                                                                          d="M6 12c-3.308 0-6-2.692-6-6s2.692-6 6-6 6 2.692 6 6-2.692 6-6 6zM6 .6C3.105.6.6 3.105.6 6c0 2.895 2.505 5.4 5.4 5.4 2.895 0 5.4-2.505 5.4-5.4C11.4 3.105 8.895.6 6 .6zm2.7 5.7H6c-.207 0-.3-.093-.3-.3V2.4c0-.207.093-.3.3-.3s.3.093.3.3v3.3h2.4c.207 0 .3.093.3.3s-.093.3-.3.3z"></path>*/}
{/*                                                                </g>*/}
{/*                                                            </svg>*/}

{/*                                                            Not Submitted*/}
{/*                                                        </span>*/}
{/*                                                        <a className="btn btn-trash btn-notext" rel="nofollow"*/}
{/*                                                           data-method="delete"*/}
{/*                                                           href="/family/14/delete_inquiries_school">*/}
{/*                                                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"*/}
{/*                                                                 preserveAspectRatio="xMidYMid" width="12" height="12"*/}
{/*                                                                 viewBox="-1 0 31 35">*/}
{/*                                                                <path fill-rule="evenodd" className="trash-svg"*/}
{/*                                                                      fill="#a6bedc"*/}
{/*                                                                      d="M25.500,11.500 L25.500,27.500 C25.500,29.709 23.709,31.501 21.500,31.501 L7.500,31.501 C5.291,31.501 3.500,29.709 3.500,27.500 L3.500,11.500 C2.395,11.500 1.500,10.605 1.500,9.500 L1.500,7.500 C1.500,6.395 2.395,5.500 3.500,5.500 L9.500,5.500 L9.500,3.500 C9.500,2.395 10.395,1.500 11.500,1.500 L17.500,1.500 C18.605,1.500 19.500,2.395 19.500,3.500 L19.500,5.500 L25.500,5.500 C26.605,5.500 27.500,6.395 27.500,7.500 L27.500,9.500 C27.500,10.605 26.605,11.500 25.500,11.500 ZM5.500,11.500 L5.500,27.500 C5.500,28.605 6.395,29.501 7.500,29.501 L21.500,29.501 C22.605,29.501 23.500,28.605 23.500,27.500 L23.500,11.500 C22.375,11.500 6.875,11.500 5.500,11.500 ZM11.500,5.500 L11.500,4.500 C11.500,3.948 11.948,3.500 12.500,3.500 L16.500,3.500 C17.052,3.500 17.500,3.948 17.500,4.500 L17.500,5.500 C16.531,5.500 11.500,5.500 11.500,5.500 M24.500,7.500 L4.500,7.500 C3.948,7.500 3.500,7.948 3.500,8.500 C3.500,9.053 3.948,9.500 4.500,9.500 L24.500,9.500 C25.052,9.500 25.500,9.053 25.500,8.500 C25.500,7.948 25.052,7.500 24.500,7.500 ZM9.500,14.500 C10.052,14.500 10.500,14.948 10.500,15.500 L10.500,25.500 C10.500,26.053 10.052,26.500 9.500,26.500 C8.948,26.500 8.500,26.053 8.500,25.500 L8.500,15.500 C8.500,14.948 8.948,14.500 9.500,14.500 ZM14.500,14.500 C15.052,14.500 15.500,14.948 15.500,15.500 L15.500,25.500 C15.500,26.053 15.052,26.500 14.500,26.500 C13.948,26.500 13.500,26.053 13.500,25.500 L13.500,15.500 C13.500,14.948 13.948,14.500 14.500,14.500 ZM19.500,14.500 C20.052,14.500 20.500,14.948 20.500,15.500 L20.500,25.500 C20.500,26.053 20.052,26.500 19.500,26.500 C18.948,26.500 18.500,26.053 18.500,25.500 L18.500,15.500 C18.500,14.948 18.948,14.500 19.500,14.500 Z"></path>*/}
{/*                                                            </svg>*/}
{/*                                                        </a>*/}
{/*                                                    </div>*/}
{/*                                                </td>*/}
{/*                                            </tr>*/}
{/*                                            </tbody>*/}
{/*                                        </table>*/}
{/*                                        <div className="modal fade ui-modal" id="confirm-message">*/}
{/*                                            <div className="modal-dialog">*/}
{/*                                                <div className="modal-content">*/}
{/*                                                    <div className="modal-header bar bar-inset">*/}
{/*                                                        <div className="title">*/}
{/*                                                            Confirm Message*/}
{/*                                                        </div>*/}
{/*                                                        <div className="pull-right">*/}
{/*                                                            <button aria-label="Close" className="close"*/}
{/*                                                                    data-dismiss="modal" type="button">*/}
{/*                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20"*/}
{/*                                                                     height="20" viewBox="0 0 20 20">*/}
{/*                                                                    <g fill="none" fill-rule="evenodd">*/}
{/*                                                                        <path fill="#FFF" fill-opacity="0"*/}
{/*                                                                              d="M0 0H20V20H0z"></path>*/}
{/*                                                                        <path fill="#859BBB"*/}
{/*                                                                              d="M15.6 5.8L11.4 10l4.2 4.2.7.7c.223.223.7.524.7.864 0 .683-.552 1.237-1.235 1.237-.341 0-.641-.478-.864-.701l-.701-.7-4.2-4.2-4.2 4.2-.7.7c-.223.223-.524.7-.864.7C3.553 17 3 16.448 3 15.765c0-.34.477-.64.7-.864l.7-.7L8.6 10 4.4 5.8l-.7-.7c-.223-.223-.7-.524-.7-.864C3 3.553 3.553 3 4.236 3c.34 0 .64.477.864.7l.7.7L10 8.6l4.2-4.2.7-.7c.224-.223.524-.7.865-.7C16.448 3 17 3.553 17 4.236c0 .34-.477.64-.7.864l-.7.7z"></path>*/}
{/*                                                                    </g>*/}
{/*                                                                </svg>*/}

{/*                                                            </button>*/}
{/*                                                        </div>*/}
{/*                                                    </div>*/}
{/*                                                    <div className="modal-body">*/}

{/*                                                        <div className="res-modal">*/}
{/*                                                            <p className="js-modal-message"></p>*/}
{/*                                                        </div>*/}
{/*                                                        <div className="bar bar-inset clearfix bar-bottom">*/}
{/*                                                            <div className="pull-right">*/}
{/*                                                                <a className="btn btn-warning" data-dismiss="modal" href="#">Cancel</a>*/}
{/*                                                                <span className="or modal-confirm-btn">*/}
{/*                                                                    or*/}
{/*                                                                    <input type="submit" name="commit" value="Confirm" className="btn btn-primary" data-disable-with="Confirm" />*/}
{/*                                                                </span>*/}
{/*                                                            </div>*/}
{/*                                                        </div>*/}

{/*                                                    </div>*/}
{/*                                                </div>*/}
{/*                                            </div>*/}
{/*                                        </div>*/}
{/*                                </form>*/}
{/*                            </div>*/}
{/*                            <div className="inquiries-mobile">*/}
{/*                                <div className="row table-box">*/}
{/*                                    <div className="col-xs-12">*/}
{/*                                        <div className="d-flex align-center school-title">*/}
{/*                                            <div className="box">*/}
{/*                                                <img className="school-logo -xsm"*/}
{/*                                                     src="https://search.openapply.com/assets/feg_logo-5dd925e3e16df03acbb3bd95c1940a0b40f7e1bc5a710f7befbc846df8cc1d4c.png" />*/}
{/*                                                <a className="name" href="#">Faria International School*/}
{/*                                                </a>*/}
{/*                                            </div>*/}
{/*                                            <a className="btn btn-trash btn-notext flex-right" rel="nofollow"*/}
{/*                                               data-method="delete" href="#">*/}
{/*                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"*/}
{/*                                                     preserveAspectRatio="xMidYMid" width="12" height="12"*/}
{/*                                                     viewBox="-1 0 31 35">*/}
{/*                                                    <path fill-rule="evenodd" className="trash-svg" fill="#a6bedc"*/}
{/*                                                          d="M25.500,11.500 L25.500,27.500 C25.500,29.709 23.709,31.501 21.500,31.501 L7.500,31.501 C5.291,31.501 3.500,29.709 3.500,27.500 L3.500,11.500 C2.395,11.500 1.500,10.605 1.500,9.500 L1.500,7.500 C1.500,6.395 2.395,5.500 3.500,5.500 L9.500,5.500 L9.500,3.500 C9.500,2.395 10.395,1.500 11.500,1.500 L17.500,1.500 C18.605,1.500 19.500,2.395 19.500,3.500 L19.500,5.500 L25.500,5.500 C26.605,5.500 27.500,6.395 27.500,7.500 L27.500,9.500 C27.500,10.605 26.605,11.500 25.500,11.500 ZM5.500,11.500 L5.500,27.500 C5.500,28.605 6.395,29.501 7.500,29.501 L21.500,29.501 C22.605,29.501 23.500,28.605 23.500,27.500 L23.500,11.500 C22.375,11.500 6.875,11.500 5.500,11.500 ZM11.500,5.500 L11.500,4.500 C11.500,3.948 11.948,3.500 12.500,3.500 L16.500,3.500 C17.052,3.500 17.500,3.948 17.500,4.500 L17.500,5.500 C16.531,5.500 11.500,5.500 11.500,5.500 M24.500,7.500 L4.500,7.500 C3.948,7.500 3.500,7.948 3.500,8.500 C3.500,9.053 3.948,9.500 4.500,9.500 L24.500,9.500 C25.052,9.500 25.500,9.053 25.500,8.500 C25.500,7.948 25.052,7.500 24.500,7.500 ZM9.500,14.500 C10.052,14.500 10.500,14.948 10.500,15.500 L10.500,25.500 C10.500,26.053 10.052,26.500 9.500,26.500 C8.948,26.500 8.500,26.053 8.500,25.500 L8.500,15.500 C8.500,14.948 8.948,14.500 9.500,14.500 ZM14.500,14.500 C15.052,14.500 15.500,14.948 15.500,15.500 L15.500,25.500 C15.500,26.053 15.052,26.500 14.500,26.500 C13.948,26.500 13.500,26.053 13.500,25.500 L13.500,15.500 C13.500,14.948 13.948,14.500 14.500,14.500 ZM19.500,14.500 C20.052,14.500 20.500,14.948 20.500,15.500 L20.500,25.500 C20.500,26.053 20.052,26.500 19.500,26.500 C18.948,26.500 18.500,26.053 18.500,25.500 L18.500,15.500 C18.500,14.948 18.948,14.500 19.500,14.500 Z"></path>*/}
{/*                                                </svg>*/}

{/*                                            </a>*/}
{/*                                        </div>*/}
{/*                                    </div>*/}
{/*                                    <div className="col-sm-4 col-xs-6">*/}
{/*                                        <div className="form-group static">*/}
{/*                                            <label>*/}
{/*                                                City*/}
{/*                                            </label>*/}
{/*                                            <div className="text">*/}
{/*                                                Chicago*/}
{/*                                            </div>*/}
{/*                                        </div>*/}
{/*                                    </div>*/}
{/*                                    <div className="col-sm-4 col-xs-6">*/}
{/*                                        <div className="form-group static">*/}
{/*                                            <label>*/}
{/*                                                Country*/}
{/*                                            </label>*/}
{/*                                            <div className="text">*/}
{/*                                                United States*/}
{/*                                            </div>*/}
{/*                                        </div>*/}
{/*                                    </div>*/}
{/*                                    <div className="col-sm-4 col-xs-6">*/}
{/*                                        <div className="form-group static">*/}
{/*                                            <label>*/}
{/*                                                Child*/}
{/*                                            </label>*/}
{/*                                            <div className="text">*/}
{/*                                                <img className="avatar -ds"*/}
{/*                                                     src="https://search.openapply.com/assets/fallbacks/avatar-d92b62e325a1bb53492819a8e9fc60da7c46dd3390459ce4ac27d6eb57dd1773.png" />*/}
{/*                                                <a className="name" href="#">Sharon Arese</a>*/}
{/*                                            </div>*/}
{/*                                        </div>*/}
{/*                                    </div>*/}
{/*                                    <div className="col-sm-4 col-xs-6">*/}
{/*                                        <div className="form-group static">*/}
{/*                                            <label>*/}
{/*                                                Status*/}
{/*                                            </label>*/}
{/*                                            <div className="status-action">*/}
{/*<span className="badge-icon badge-pending">*/}
{/*<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">*/}
{/*    <g fill="none" fill-rule="evenodd">*/}
{/*        <path fill="#FFF" fill-opacity="0" d="M0 0H12V12H0z"></path>*/}
{/*        <path fill="#F59D00"*/}
{/*              d="M6 12c-3.308 0-6-2.692-6-6s2.692-6 6-6 6 2.692 6 6-2.692 6-6 6zM6 .6C3.105.6.6 3.105.6 6c0 2.895 2.505 5.4 5.4 5.4 2.895 0 5.4-2.505 5.4-5.4C11.4 3.105 8.895.6 6 .6zm2.7 5.7H6c-.207 0-.3-.093-.3-.3V2.4c0-.207.093-.3.3-.3s.3.093.3.3v3.3h2.4c.207 0 .3.093.3.3s-.093.3-.3.3z"></path>*/}
{/*    </g>*/}
{/*</svg>*/}

{/*Pending*/}
{/*</span>*/}
{/*                                            </div>*/}
{/*                                        </div>*/}
{/*                                    </div>*/}
{/*                                </div>*/}
{/*                            </div>*/}
{/*                        </div>*/}
                    </div>
                </div>

            </div>
        </main>
    )
}

export default ProfilePage