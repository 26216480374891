import React, {useEffect, useState} from "react";
import imgPerson from "../img/ic-person.png"
import {useUserContext} from "../context/userContext";
import {onValue, ref} from "firebase/database";
import {db} from "../firebase";
import SEO from "../seo/SEO";

function ParentInformationPage() {
    const [{user}, dispatch] = useUserContext()

    const [parentData, setParentData] = useState([])

    useEffect(() => {
        onValue(ref(db, `easyApplications/profile/${user}/parentsInfo`), snapshot => {
            if (snapshot.exists()) {
                setParentData([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((item) => {
                        setParentData((oldArray) => [...oldArray, item])
                    })
                }
            }
        })
    }, [user])

    return (
        <div className="panel w-clearfix">
            <SEO title="Parent or Guardian Information | Easy Applications"
                 description="Easy Applications is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://apply.easyapplications.co.za" />
            <div>
                <div className="buttons-row w-row">
                    <main className="-no-padding-top">
                        <div className="content-wrapper" style={{ textAlign: "left" }}>
                            <div className="bar title bar-inset clearfix bar-top">
                                <div className="title">
                                    Parent/Guardian
                                </div>
                                <div className="right-block add-parent-wrapper">
                                    <a className="btn btn-primary add-parent js-add-parent item add_fields" href="/parents/new">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                            <g fill="none" fillRule="evenodd">
                                                <path fill="#FFF" fillOpacity="0" d="M0 0H12V12H0z"></path>
                                                <path fill="#859BBB"
                                                      d="M6 0c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6C.001 2.687 2.687.001 6 0zm0 .8C3.128.8.8 3.128.8 6c0 2.872 2.328 5.2 5.2 5.2 2.872 0 5.2-2.328 5.2-5.2C11.2 3.128 8.872.8 6 .8zm0 1.6c.22 0 .4.18.4.4v2.8h2.8c.22 0 .4.18.4.4 0 .22-.18.4-.4.4H6.4v2.8c0 .22-.18.4-.4.4-.22 0-.4-.18-.4-.4V6.4H2.8c-.22 0-.4-.18-.4-.4 0-.22.18-.4.4-.4h2.8V2.8c0-.22.18-.4.4-.4z"></path>
                                            </g>
                                        </svg>

                                        Add New Parent
                                    </a>
                                </div>
                            </div>
                            {parentData.map((item) => (
                                <div className="content-block registration-form">
                                    <div className="show-profile applicant-details-info">
                                        <div className="bar bar-default bar-offset -no-top-border">
                                            <div className="person-block form-group">
                                                <img className="avatar -md" src={imgPerson} alt=""/>
                                                <div className="description">
                                                    <div className="name">{`${item.firstName} ${item.surname}`}</div>
                                                    <div className="role">Registered on {new Date(item.dateJoined).toLocaleDateString()}</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                    <div className="info-label">First Name:</div>
                                                    <div className="info-text">{item.firstName}</div>
                                                </div>
                                                <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                    <div className="info-label">Last Name:</div>
                                                    <div className="info-text">{item.surname}</div>
                                                </div>
                                                <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                    <div className="info-label">Relationship:</div>
                                                    <div className="info-text">{item.relationship}</div>
                                                </div>
                                                <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                    <div className="info-label">Email:</div>
                                                    <div className="info-text">{item.email}</div>
                                                </div>
                                                <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                    <div className="info-label">Phone:</div>
                                                    <div className="info-text">+27{item.phoneNumber}</div>
                                                </div>
                                                <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                    <div className="info-label">City or Town:</div>
                                                    <div className="info-text">{item.town}</div>
                                                </div>
                                                <div className="info-text-field col-md-4 col-xs-12 form-group">
                                                    <div className="info-label">Street Address:</div>
                                                    <div className="info-text">{item.address}</div>
                                                </div>
                                                <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                    <div className="info-label">Province:</div>
                                                    <div className="info-text">{item.province}</div>
                                                </div>
                                                <div className="info-text-field col-md-4 col-xs-6 form-group">
                                                    <div className="info-label">Country:</div>
                                                    <div className="info-text">South Africa</div>
                                                </div>
                                            </div>
                                            <div className="bar bar-inset clearfix -no-border">
                                                <div className="pull-right">
                                                    <a href={`/parents/edit/${item.userId}`} className="btn btn-default">
                                                        Edit Parent Information
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default ParentInformationPage