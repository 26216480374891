import React, {useEffect, useState} from "react";
import {useUserContext} from "../context/userContext";
import {onValue, ref} from "firebase/database";
import {db} from "../firebase";
import {LEARNER_ID, LEARNER_NAME} from "../utils/actions";

function Banner() {
    const [{user, learner}, dispatch] = useUserContext()

    const [learnerData, setLearnerData] = useState([])
    const [selectedLearner, setSelectedLearner] = useState(0)

    useEffect(() => {
        onValue(ref(db, `easyApplications/myLearners/${user}`), snapshot => {
            if (snapshot.exists()) {
                setLearnerData([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((item) => {
                        setLearnerData((oldArray) => [...oldArray, item])
                    })
                    if (learnerData.length !== 0 && learner === "") {
                        const fullName = `${learnerData[0].firstName} ${learnerData[0].surname}`
                        const learnerId = learnerData[0].learnerId

                        dispatch({
                            type: LEARNER_NAME,
                            learner: fullName
                        })

                        dispatch({
                            type: LEARNER_ID,
                            learnerId: learnerId
                        })

                        localStorage.setItem('learner', JSON.stringify(fullName))
                        localStorage.setItem('learnerId', JSON.stringify(learnerId))

                    } else {
                        setSelectedLearner(null)
                    }

                }
            }
        })



    }, [user])

    const handleChange = (e) => {
        const value = e.target.value
        const index = e.target.options.selectedIndex
        const learnerId = learnerData[index].learnerId

        dispatch({
            type: LEARNER_NAME,
            learner: value
        })

        dispatch({
            type: LEARNER_ID,
            learnerId: learnerId
        })

        localStorage.setItem('learner', JSON.stringify(value))
        localStorage.setItem('learnerId', JSON.stringify(learnerId))
    }

    return (
        <div className="res-content -no-padding-top" style={{ textAlign: "left" }}>
            <div className="row">
                <div className="col-xs-12">
                    <div className="form-group select2-wrapper children-select">
                        <label className='h3' style={{ marginBottom: 12 }}>APPLICANT PROFILE</label>
                        <select className="form-control" value={learner} onChange={handleChange} >
                            {learnerData.map((item, key) => (
                                <option value={`${item.firstName} ${item.surname}`} name={key}>
                                    {`${item.firstName} ${item.surname}`}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner