import React, {useState} from "react";
import SEO from "../seo/SEO";
import {auth} from "../firebase";

function ForgotPasswordPage() {
    const [email, setEmail] = useState()

    const resetPassword = (e) => {
        e.preventDefault()

        auth.sendPasswordResetEmail(email).then(() => {
            alert(`Link to reset password has been sent to ${email}`)
        }).catch((err) => {
            alert(err.message)
        })
    }

    return (
        <div className="full-container">
            <SEO title="Forgot Password | Easy Applications"
                 description="Easy Applications is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://apply.easyapplications.co.za/forget-password" />
            <div className="modal-box login">
                <h2 className="h2">Forgot Password?</h2>
                <div className="w-form full-width">
                    <form id="email-form" name="email-form" onSubmit={resetPassword}>
                        <label htmlFor="name" className="field-label">Email:</label>
                        <input type="email" id="email" name="email" placeholder="Enter your email" required
                               maxLength="256" className="text-field w-input" value={email} onChange={e => setEmail(e.target.value)}/>
                        <input type="submit" value="Reset Password" data-wait="Plase wait..." className="button full-width w-button"/>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordPage